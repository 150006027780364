import {
  CollapsableContents,
  DisplayErrorMessagesIfPresent,
  LegislatorBillsScreenUI,
  NoInfoToDisplay,
  getLegislatorEventBillsV5,
} from "@constituentvoice/cv-elements/web";
import { faScroll } from "@fortawesome/pro-regular-svg-icons/faScroll";
import { ActivityIndicator } from "react-native";

interface LegislatorBillsViewProps {
  onPressBillDetailsNav: (
    bill: Awaited<ReturnType<typeof getLegislatorEventBillsV5>>[0] | undefined,
  ) => void;
  billDetails:
    | Awaited<ReturnType<typeof getLegislatorEventBillsV5>>
    | undefined;
  billsLoading: boolean;
  billDetailsError: unknown;
  legislatorId?: number | string;
}

export const LegislatorBillsView = ({
  onPressBillDetailsNav,
  billDetails = [],
  billsLoading,
  billDetailsError,
  legislatorId,
}: LegislatorBillsViewProps) => {
  if (billsLoading) return <ActivityIndicator size="large" />;
  return (
    <CollapsableContents icon={faScroll} title={"Bills"}>
      <DisplayErrorMessagesIfPresent
        errors={[{ name: "Bill Details Error", val: billDetailsError }]}
      />
      {billDetails.length > 0 ? (
        <LegislatorBillsScreenUI
          bills={billDetails}
          onPressBillNavigation={onPressBillDetailsNav}
          meeting={undefined}
          legislatorId={legislatorId}
        />
      ) : (
        <NoInfoToDisplay />
      )}
    </CollapsableContents>
  );
};
