import * as React from "react";

import { useAppTitle } from "../../hooks/use-client-title";

import { HomeScreenViewV5 } from "./HomeScreen-v5.view";

export function HomeScreenView() {
  useAppTitle("Your Meetings");
  return <HomeScreenViewV5 />;
}
