import {
  DisplayErrorMessagesIfPresent,
  EventLegislatorsScreenUI,
  USState,
  getEventLegislatorsV5,
} from "@constituentvoice/cv-elements/web";
import * as React from "react";
import { ActivityIndicator } from "react-native";
import { useNavigate } from "react-router-dom";

import { useBuildClientRoute } from "../../../hooks/use-build-client-route";

import { ActivityIndicatorView } from "./EventLegislatorsScreen.styles";

interface EventLegislatorsScreenViewWebProps {
  searchTerm?: string;
  stateFilter?: Set<USState["abbreviation"]>;
  eventLegislatorsLoading: boolean;
  eventLegislatorsError: unknown;
  eventLegislators?: Awaited<ReturnType<typeof getEventLegislatorsV5>>;
}

export const EventLegislatorsScreenView = ({
  searchTerm,
  stateFilter,
  eventLegislatorsLoading,
  eventLegislatorsError,
  eventLegislators,
}: EventLegislatorsScreenViewWebProps) => {
  const navigate = useNavigate();
  const buildRoute = useBuildClientRoute();

  const userOnPress = (
    user: Awaited<ReturnType<typeof getEventLegislatorsV5>>[0],
  ) => {
    navigate(buildRoute(`/legislators-home/${user?.id}`));
  };

  const filteredEventLegislators = React.useMemo(() => {
    if (eventLegislatorsError || eventLegislatorsLoading) return [];
    if (!eventLegislators || !eventLegislators?.length) return [];

    return (eventLegislators as never[])
      .filter((legislator: (typeof eventLegislators)[number]) => {
        if (!searchTerm || !searchTerm?.length) return true;
        return `${legislator.firstName ?? ""} ${legislator.lastName ?? ""}`
          .toLowerCase()
          .includes(searchTerm.trim().toLowerCase());
      })
      .filter((legislator: (typeof eventLegislators)[number]) => {
        if (!stateFilter || !stateFilter?.size) return true;
        return Array.from(stateFilter).some((state) =>
          legislator.state.toLowerCase().includes(state.toLowerCase()),
        );
      });
  }, [
    eventLegislators,
    eventLegislatorsError,
    eventLegislatorsLoading,
    searchTerm,
    stateFilter,
  ]);

  if (eventLegislatorsLoading) {
    return (
      <ActivityIndicatorView>
        <ActivityIndicator size="large" />
      </ActivityIndicatorView>
    );
  }

  return (
    <>
      <DisplayErrorMessagesIfPresent
        errors={[{ name: "Fetching Legislators", val: eventLegislatorsError }]}
      />
      <EventLegislatorsScreenUI
        eventLegislators={filteredEventLegislators}
        userOnPress={userOnPress as never}
      />
    </>
  );
};
