import { CVButton } from "@constituentvoice/cv-elements/web";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

import { Popover } from "../aria/Popover";

export const StyledButton = styled(CVButton).attrs({
  variant: "accent",
})`
  padding: 0;
  border-radius: 0.25rem;
  transition: all 0.1s ease-in-out;

  &[aria-haspopup][aria-expanded="true"] {
    background-color: ${(p) => p.theme.colors.white};
    color: ${(p) => p.theme.colors.onPrimary};
    border-color: ${(p) => p.theme.colors.white};
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow:
      0px 2.75px 9px 0px rgba(0, 0, 0, 0.19),
      0px 0.25px 3px 0px rgba(0, 0, 0, 0.04);
  }
`;

export const MenuButtonContainer = styled.div`
  display: flex;
  align-items: center;
  text-align: start;
  gap: 0.5rem;
`;

export const MenuButtonText = styled.span`
  flex: 1;
`;

export const RotatingIcon = styled(FontAwesomeIcon)<{ open?: boolean }>`
  transition: transform 0.2s ease-in-out;
  transform: rotate(${(p) => (p.open ? 180 : 0)}deg);
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  width: 3rem;
  height: 3rem;
`;

export const UserMenuPopover = styled(Popover)`
  border-radius: 0 0 0.25rem 0.25rem;
  box-shadow:
    0px 2.75px 9px 0px rgba(0, 0, 0, 0.19),
    0px 0.25px 3px 0px rgba(0, 0, 0, 0.04);

  & > ul {
    gap: 0.5rem;
    padding: 0.5rem 0;

    & > li:nth-last-child(2) {
      border-bottom: 0.0625rem solid ${(p) => p.theme.colors.outlineVariant};
    }

    & > li:last-child {
      color: ${(p) => p.theme.colors.negative};
    }
  }
`;
