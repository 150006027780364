import {
  DisplayErrorMessagesIfPresent,
  MeetingDocumentsScreenUI,
  useMeetingDocumentsV5,
} from "@constituentvoice/cv-elements/web";
import { ActivityIndicator, RefreshControl } from "react-native";
import { useParams } from "react-router-dom";

import { DocumentsScreenScrollView } from "./MeetingDocumentsScreen.styles";

interface MeetingDocumentsScreenProps {
  meetingId: string | number;
}

export const MeetingDocumentsScreenViewV5 = () => {
  const { meetingId } = useParams<
    keyof MeetingDocumentsScreenProps
  >() as MeetingDocumentsScreenProps;

  const {
    refetch,
    isLoading,
    isRefetching,
    openDocumentError,
    documentsError,
    allDocs,
    openDocument,
  } = useMeetingDocumentsV5({ meetingId });

  if (isLoading) {
    return <ActivityIndicator accessibilityLabel="Loading..." size="large" />;
  }

  return (
    <DocumentsScreenScrollView
      refreshControl={
        <RefreshControl
          refreshing={isRefetching}
          onRefresh={refetch as never}
        />
      }
    >
      <DisplayErrorMessagesIfPresent
        errors={[
          { name: "Opening your Documents", val: openDocumentError },
          { name: "Retrieving Documents", val: documentsError },
        ]}
      />
      <MeetingDocumentsScreenUI allDocs={allDocs} openDocument={openDocument} />
    </DocumentsScreenScrollView>
  );
};
