import type { APISupportFormDataV5 } from "@constituentvoice/cv-elements/web";
import {
  DisplayErrorMessagesIfPresent,
  Modal,
  SupportScreenUI,
  sendSupportPrivateV5,
  useSupportV5,
} from "@constituentvoice/cv-elements/web";
import { useOverlayTriggerState } from "@react-stately/overlays";
import { useMutation } from "@tanstack/react-query";
import { ActivityIndicator } from "react-native";

import { AlertDialog } from "../../components/AlertDialog/AlertDialog";
import { useAppTitle } from "../../hooks/use-client-title";

export const SupportScreenView = () => {
  const submitSuccessfulModalState = useOverlayTriggerState({});
  useAppTitle("Request Support");

  const {
    supportData,
    supportDataLoading,
    supportDataRefetch,
    supportDataError,
    networkingProps,
  } = useSupportV5();

  const { mutate: submitSupport, error: submitSupportError } = useMutation({
    mutationFn: async (values: APISupportFormDataV5) => {
      const response = await sendSupportPrivateV5({
        ...networkingProps,
        body: values,
      });
      if (response) {
        submitSuccessfulModalState.setOpen(true);
      }
      void supportDataRefetch();
    },
  });

  if (supportDataLoading) {
    return <ActivityIndicator accessibilityLabel="Loading..." size="large" />;
  }

  return (
    <>
      <DisplayErrorMessagesIfPresent
        errors={[
          // Displaying this error creates a disruptive and unfavorable user experience.
          // { name: "Support", val: supportDataError },
          { name: "Submitting Support Form", val: submitSupportError },
        ]}
      />
      <SupportScreenUI
        supportData={supportData}
        onSubmit={(values: APISupportFormDataV5) => {
          submitSupport(values);
        }}
      />
      <Modal state={submitSuccessfulModalState}>
        <AlertDialog
          closeLabel={"Ok"}
          onClose={() => submitSuccessfulModalState.setOpen(false)}
        >
          Support ticket submitted successfully!
        </AlertDialog>
      </Modal>
    </>
  );
};
