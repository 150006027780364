import {
  refetchToken,
  useAppDispatch,
  useAppSelector,
} from "@constituentvoice/cv-elements/web";
import { useMutation } from "@tanstack/react-query";
import * as React from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import { useBuildClientRoute } from "../../hooks/use-build-client-route";
import { useAppTitle } from "../../hooks/use-client-title";

import { LoginScreenUI } from "./LoginScreen.ui";

export const LoginScreenView = () => {
  useAppTitle("Login");

  const dispatch = useAppDispatch();

  const client = useAppSelector((state) => state.client.client);
  const env = useAppSelector((state) => state.env.env!);
  const token = useAppSelector((state) => state.user.token);

  const navigate = useNavigate();
  const buildRoute = useBuildClientRoute();

  const supportOnPress = () => {
    navigate(buildRoute("/support"));
  };

  const { error, mutate: onSignedIn } = useMutation({
    mutationFn: async () => {
      await dispatch(refetchToken());
    },
  });

  const location = useLocation();

  // User is already logged in
  if (token) {
    return <Navigate to={location.state?.redirectTo || "../"} replace={true} />;
  }

  return (
    <LoginScreenUI
      client={client!}
      supportOnPress={supportOnPress}
      onSignedIn={onSignedIn}
      env={env}
    />
  );
};
