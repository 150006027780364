import { Image, Pressable, SafeAreaView, Text, View } from "react-native";
import styled from "styled-components/native";

export const ErrorSafeAreaView = styled(SafeAreaView)`
  background-color: white;
  color: white;
  flex: 1;
  padding: 16px;
`;

export const ErrorView = styled(View)`
  justify-content: center;
`;

export const SomethingWrongView = styled(View)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ErrorText = styled(Text)`
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 20px;
`;

export const ReloadView = styled(View)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

export const ReloadPressable = styled(Pressable)`
  color: blue;
  border: 1px solid black;
  border-radius: 5px;
  background-color: #9dd8f3;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px;
`;

export const ShowMorePressable = styled(Pressable)`
  color: blue;
  border: 1px solid black;
  border-radius: 5px;
  background-color: #ff7a7b;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin-top: 20px;
`;

export const SectionedErrorView = styled(View)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

export const ShowMoreText = styled(Text)`
  margin: 10px;
  margin-bottom: 5px;
`;

export const ErrorMessageText = styled(Text)`
  margin-top: 20px;
  text-align: center;
`;
