import { CollapsableContents } from "@constituentvoice/cv-elements/web";
import styled from "styled-components";

export const MeetingSurveyScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const SurveyScreenContent = styled(CollapsableContents).attrs({
  canExpand: false,
})`
  & > div:nth-of-type(2) {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1.5rem;
  }
`;

export const PreviouslySubmittedBanner = styled.div`
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 9px;
  background: ${(props) => props.theme.colors.positive};
`;
