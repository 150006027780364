import { faCamera } from "@fortawesome/pro-regular-svg-icons/faCamera";
import { faImagePolaroid } from "@fortawesome/pro-regular-svg-icons/faImagePolaroid";

export const createACCirclesArray = (
  takePicture: () => void,
  showGallery: () => void,
) => {
  return [
    {
      color: "#4a4e4d",
      icon: faCamera,
      onPress: takePicture,
    },
    {
      color: "#0e9aa7",
      icon: faImagePolaroid,
      onPress: showGallery,
    },
  ];
};
