import { useMenuSection } from "@react-aria/menu";
import { useSeparator } from "@react-aria/separator";
import { TreeState } from "@react-stately/tree";

import { MenuItem } from "./MenuItem";

type Node<T> = TreeState<T>["collection"] extends Iterable<infer Q> ? Q : never;

interface MenuSectionProps<T> {
  section: Node<T>;
  state: TreeState<T>;
}

export const MenuSection = <T extends object>(props: MenuSectionProps<T>) => {
  const { section, state } = props;
  const { itemProps, headingProps, groupProps } = useMenuSection({
    heading: section.rendered,
    "aria-label": section["aria-label"],
  });

  const { separatorProps } = useSeparator({
    elementType: "li",
  });

  return (
    <>
      {section.key !== state.collection.getFirstKey() && (
        <li {...separatorProps} />
      )}
      <li {...itemProps}>
        {section.rendered && <span {...headingProps}>{section.rendered}</span>}
        <ul {...groupProps}>
          {Array.from(state.collection.getChildren?.(section.key) || []).map(
            (node) => (
              <MenuItem key={node.key} item={node} state={state} />
            ),
          )}
        </ul>
      </li>
    </>
  );
};
