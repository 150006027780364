import { useAppSelector } from "@constituentvoice/cv-elements/web";
import * as React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { useBuildClientRoute } from "../hooks/use-build-client-route";

export const ValidateAuthLayout = () => {
  const token = useAppSelector((state) => state.user.token);
  const buildClientRoute = useBuildClientRoute();
  const location = useLocation();

  if (!token) {
    return (
      <Navigate
        to={buildClientRoute("/login")}
        replace={true}
        state={{ redirectTo: location.pathname }}
      />
    );
  }

  return <Outlet />;
};
