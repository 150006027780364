import type { AriaPopoverProps } from "@react-aria/overlays";
import { DismissButton, Overlay, usePopover } from "@react-aria/overlays";
import type { OverlayTriggerState } from "@react-stately/overlays";
import type { DOMProps } from "@react-types/shared";
import * as React from "react";

import { PopoverContent, Underlay } from "./Popover.styles";

interface PopoverProps extends Omit<AriaPopoverProps, "popoverRef"> {
  children: React.ReactNode;
  state: OverlayTriggerState;
  popoverRef?: React.RefObject<HTMLDivElement>;
  className?: string;
  style?: React.CSSProperties;
  overlayProps?: Omit<DOMProps, "children">;
}

export const Popover = ({ overlayProps, ...props }: PopoverProps) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const { popoverRef = ref, state, children, isNonModal } = props;

  const { popoverProps, underlayProps } = usePopover(
    {
      ...props,
      popoverRef,
    },
    state,
  );

  return (
    <Overlay {...overlayProps}>
      {!isNonModal && <Underlay {...underlayProps} />}
      <PopoverContent
        {...popoverProps}
        ref={popoverRef}
        className={props.className}
        style={{
          ...popoverProps.style,
          ...props.style,
        }}
      >
        {!isNonModal && (
          <DismissButton
            onDismiss={
              // eslint-disable-next-line @typescript-eslint/unbound-method
              state.close
            }
          />
        )}
        {children}
        <DismissButton
          onDismiss={
            // eslint-disable-next-line @typescript-eslint/unbound-method
            state.close
          }
        />
      </PopoverContent>
    </Overlay>
  );
};
