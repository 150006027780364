import {
  VotedLegislatorsListsProps,
  VotedLegislatorsListsUI,
  useVotedLegislatorsLists,
} from "@constituentvoice/cv-elements/web";

interface VotedLegislatorsListsViewProps {
  votedYesLegislators: VotedLegislatorsListsProps["votedYesData"]["filteredLegislators"];
  votedNoLegislators: VotedLegislatorsListsProps["votedYesData"]["filteredLegislators"];
}

export const VotedLegislatorsListsView = ({
  votedYesLegislators,
  votedNoLegislators,
}: VotedLegislatorsListsViewProps) => {
  const props = useVotedLegislatorsLists(
    votedYesLegislators,
    votedNoLegislators,
  );
  return <VotedLegislatorsListsUI {...props} />;
};
