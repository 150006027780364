import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { faUser } from "@fortawesome/pro-solid-svg-icons/faUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useButton } from "@react-aria/button";
import { AriaMenuProps, useMenuTrigger } from "@react-aria/menu";
import type { MenuTriggerProps } from "@react-stately/menu";
import { useMenuTriggerState } from "@react-stately/menu";
import type { CSSProperties } from "react";
import { useRef } from "react";

import { useAnchorElementStyles } from "../../hooks/use-anchor-element-styles";
import { Menu } from "../aria/Menu";

import {
  IconContainer,
  MenuButtonContainer,
  MenuButtonText,
  RotatingIcon,
  StyledButton,
  UserMenuPopover,
} from "./UserMenuButton.styles";

interface MenuButtonProps<T> extends AriaMenuProps<T>, MenuTriggerProps {
  label?: string;

  // @Spelkington commented out RE: https://discord.com/channels/319859947238195203/795710651267153960/1275887381202997268
  // onAction: (e: string) => void;
  className?: string;
  style?: CSSProperties;
  icon?: IconDefinition;
  menuIcon?: IconDefinition;
}

export const UserMenuButton = <T extends object>(props: MenuButtonProps<T>) => {
  const state = useMenuTriggerState(props);

  const ref = useRef<HTMLButtonElement>(null);
  const { menuTriggerProps, menuProps } = useMenuTrigger<T>({}, state, ref);
  const { buttonProps } = useButton(menuTriggerProps, ref);

  const popOverStyle = useAnchorElementStyles(state.isOpen, ref);

  return (
    <>
      <StyledButton
        {...buttonProps}
        className={props.className}
        style={props.style}
        ref={ref}
      >
        <MenuButtonContainer>
          <IconContainer>
            <FontAwesomeIcon icon={props.icon || faUser} />
          </IconContainer>
          <MenuButtonText>{props.label}</MenuButtonText>
          <IconContainer>
            <RotatingIcon
              icon={props.menuIcon || faCaretDown}
              open={state.isOpen && !Boolean(props.menuIcon)}
            />
          </IconContainer>
        </MenuButtonContainer>
      </StyledButton>
      {state.isOpen && (
        <UserMenuPopover
          state={state}
          triggerRef={ref}
          placement={"bottom"}
          style={popOverStyle}
        >
          <Menu {...props} {...menuProps} />
        </UserMenuPopover>
      )}
    </>
  );
};
