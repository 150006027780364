import {
  DisplayErrorMessagesIfPresent,
  EventBillsScreenUI,
  useEventBillsV5,
} from "@constituentvoice/cv-elements/web";
import * as React from "react";
import { ActivityIndicator, View } from "react-native";
import { useNavigate } from "react-router-dom";

import { useBuildClientRoute } from "../../hooks/use-build-client-route";
import { useAppTitle } from "../../hooks/use-client-title";

import { NoBillsIllustration } from "./EventBillsScreen.styles";

export const EventBillsScreenV5 = () => {
  useAppTitle("Event Bills");
  const { billDetails, billsLoading, billError } = useEventBillsV5();
  const navigate = useNavigate();
  const buildRoute = useBuildClientRoute();

  if (billsLoading) {
    return (
      <View>
        <ActivityIndicator size="large" accessibilityLabel="Loading..." />
      </View>
    );
  }

  return (
    <>
      <DisplayErrorMessagesIfPresent
        errors={[{ name: "Fetching Bills", val: billError }]}
      />
      <EventBillsScreenUI
        billDetails={billDetails}
        moreBillsOnPress={(bill) => {
          if (!bill) return;
          navigate(buildRoute(`/event-bills/${bill.id}`));
        }}
        noEventBillsIllustration={
          <NoBillsIllustration src={"/images/no-bills-illustration.png"} />
        }
      />
    </>
  );
};
