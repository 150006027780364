import type { AriaMenuProps } from "@react-aria/menu";
import { useMenu } from "@react-aria/menu";
import { useTreeState } from "@react-stately/tree";
import { useRef } from "react";

import { MenuUnorderedList } from "./Menu.styles";
import { MenuItem } from "./MenuItem";
import { MenuSection } from "./MenuSection";

export const Menu = <T extends object>(props: AriaMenuProps<T>) => {
  const state = useTreeState(props as AriaMenuProps<any>);
  const ref = useRef(null);
  const { menuProps } = useMenu(props, state, ref);

  return (
    <MenuUnorderedList {...menuProps} ref={ref}>
      {[...state.collection].map((item) =>
        item.type === "section" ? (
          <MenuSection key={item.key} section={item} state={state} />
        ) : (
          <MenuItem key={item.key} item={item} state={state} />
        ),
      )}
    </MenuUnorderedList>
  );
};
