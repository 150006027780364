import {
  NotificationsProvider,
  ThemeProvider,
  initGeocoder,
  setEnv,
  store,
  useAPIVersion,
  useAppDispatch,
  useAppSelector,
} from "@constituentvoice/cv-elements/web";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import * as React from "react";
import { useEffect } from "react";
import { StatusBar } from "react-native";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { Provider as ReduxProvider } from "react-redux";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import { ErrorBoundary } from "./components/ErrorBoundary/ErrorBoundary.view";
import { GlobalStyles } from "./constants/global-styles";
import { config } from "./env";
import { useClientFavicon } from "./hooks/use-client-favicon";
import { routes } from "./navigators";

const queryClient = new QueryClient();

const router = createBrowserRouter(routes);

const AppBase = () => {
  // useSetupForRefreshOnFocus();
  // useMessagingToken();

  const client = useAppSelector((state) => state.client.client);

  const dispatch = useAppDispatch();
  const env = useAppSelector((state) => state.env.env);

  useEffect(() => {
    void dispatch(setEnv(config));
  }, [dispatch]);

  const apiVersion = useAPIVersion();

  // useFirebaseMessaging();

  useEffect(() => {
    initGeocoder(config.GOOGLE_MAPS.androidApiKey);
  }, []);

  useClientFavicon();

  if (!env) return <p>Loading...</p>;

  return (
    <ThemeProvider mainColor={client?.templateString}>
      <GlobalStyles />
      <RouterProvider router={router} />
    </ThemeProvider>
  );
};

export const App = () => {
  return (
    <ErrorBoundary>
      <ReduxProvider store={store}>
        <QueryClientProvider client={queryClient}>
          <NotificationsProvider>
            <AppBase />
          </NotificationsProvider>
        </QueryClientProvider>
      </ReduxProvider>
    </ErrorBoundary>
  );
};
