import {
  CollapsableContents,
  DisplayErrorMessagesIfPresent,
  LegislatorBillsScreenUI,
  useLegislatorBillsV5,
} from "@constituentvoice/cv-elements/web";
import { faScroll } from "@fortawesome/pro-solid-svg-icons/faScroll";
import * as React from "react";
import { ActivityIndicator } from "react-native";
import { useNavigate, useParams } from "react-router-dom";

import { useBuildClientRoute } from "../../hooks/use-build-client-route";

import { LegislatorBillsScreenContainer } from "./LegislatorBillsScreen.styles";

export interface IRouteParams {
  meetingId: number | string;
}

export const LegislatorBillsScreenViewV5 = () => {
  const { meetingId } = useParams<keyof IRouteParams>() as IRouteParams;
  const navigate = useNavigate();
  const buildRoute = useBuildClientRoute();

  const onPressBillNavigation = (bill: any) => {
    if (!bill) return;
    navigate(buildRoute(`/event-bills/${bill.billId}`));
  };

  const {
    meeting,
    meetingLoading,
    meetingError,
    legislatorBills,
    legislatorBillsLoading,
    legislatorBillsError,
  } = useLegislatorBillsV5({ meetingId: meetingId });

  if (meetingLoading || legislatorBillsLoading)
    return <ActivityIndicator size="large" />;

  return (
    <CollapsableContents icon={faScroll} title={"Bills"}>
      <DisplayErrorMessagesIfPresent
        errors={[
          { name: "Meetings", val: meetingError },
          { name: "Legislator's Bills", val: legislatorBillsError },
        ]}
      />
      <LegislatorBillsScreenContainer>
        <LegislatorBillsScreenUI
          bills={legislatorBills}
          onPressBillNavigation={onPressBillNavigation}
          meeting={meeting}
        />
      </LegislatorBillsScreenContainer>
    </CollapsableContents>
  );
};
