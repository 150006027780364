import {
  DisplayErrorMessagesIfPresent,
  HomeScreenUI,
  useHomeScreenV5,
} from "@constituentvoice/cv-elements/web";
import { ActivityIndicator } from "react-native";
import { useNavigate } from "react-router-dom";

import { SponsorsAds } from "../../components/SponsorsAds/SponsorsAds";
import { useBuildClientRoute } from "../../hooks/use-build-client-route";

export function HomeScreenViewV5() {
  const navigate = useNavigate();

  const {
    refetch,
    meetings,
    isLoading,
    userMeetingsError,
    sponsoradsError,
    sponsorAds,
    isRefetching,
    scheduleEnabled,
    currentUserId,
    previousEventBannerBehavior,
    currentEventName,
    clientLogo,
    noLobbyDays,
  } = useHomeScreenV5();
  const buildRoute = useBuildClientRoute();

  const onMeetingPress = (meeting: Exclude<typeof meetings, null>[0][0]) => {
    navigate(buildRoute(`/meeting/${meeting.id}`));
  };

  const onPreviousEventBannerClicked = () => {
    navigate(buildRoute("/lobby-day"));
  };

  return (
    <>
      {isLoading && (
        <ActivityIndicator accessibilityLabel="Loading..." size="large" />
      )}
      <DisplayErrorMessagesIfPresent
        errors={[
          { name: "Meetings", val: userMeetingsError },
          { name: "Sponsor Ads", val: sponsoradsError },
        ]}
      />
      <HomeScreenUI
        currentEventName={currentEventName}
        clientLogo={clientLogo}
        meetings={meetings}
        isRefetching={isRefetching}
        refetch={refetch as never}
        scheduleEnabled={scheduleEnabled}
        isLoading={isLoading}
        onMeetingPress={onMeetingPress}
        previousEventBannerBehavior={previousEventBannerBehavior}
        currentUserId={currentUserId}
        sponsorsElement={<SponsorsAds ads={sponsorAds} />}
        onPreviousEventBannerPress={onPreviousEventBannerClicked}
        noLobbyDays={noLobbyDays}
      />
    </>
  );
}
