import {
  setClient,
  useAppDispatch,
  useTheme,
} from "@constituentvoice/cv-elements/web";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useGetGroups } from "../../hooks/use-get-groups";

import { ChooseYourGroupUI } from "./choose-your-group.ui";

export const ChooseYourGroupView = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    void dispatch(setClient(null));
  }, [dispatch]);

  const navigate = useNavigate();

  const { data: groups, isLoading, isFetching, error } = useGetGroups();

  const selectClient = useCallback(
    (clientData: Exclude<typeof groups, undefined>[number]) => {
      navigate(`/${clientData.serviceCode}`);
    },
    [navigate],
  );

  const loading = isLoading || isFetching;

  const [searchTerm, setSearchTerm] = useState("");

  const filteredGroups = useMemo(() => {
    const search = searchTerm.toLowerCase();

    return groups
      ? groups.filter((group) => {
          return (
            group.vendor.toLowerCase().includes(search) ||
            group.serviceCode.toLowerCase().includes(search) ||
            group.abbreviation?.toLowerCase().includes(search)
          );
        })
      : [];
  }, [groups, searchTerm]);

  return (
    <ChooseYourGroupUI
      selectClient={selectClient}
      loading={loading}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      filteredGroups={filteredGroups}
    />
  );
};
