import styled from "styled-components";

export const Underlay = styled.div`
  position: fixed;
  inset: 0;
`;

export const PopoverContent = styled.div`
  background-color: #fff;
`;
