import {
  AttendeesScreenUI,
  CollapsableContents,
  DisplayErrorMessagesIfPresent,
  useAttendeesV5,
} from "@constituentvoice/cv-elements/web";
import { faUsers } from "@fortawesome/pro-solid-svg-icons/faUsers";
import * as React from "react";
import { ActivityIndicator } from "react-native";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useBuildClientRoute } from "../../hooks/use-build-client-route";

interface IRouteAttendeesV5 {
  meetingId: string | number;
}

export const AttendeesScreenViewV5 = () => {
  const { meetingId } = useParams<
    keyof IRouteAttendeesV5
  >() as IRouteAttendeesV5;
  const navigate = useNavigate();
  const location = useLocation();

  const {
    meetingAttendeeError,
    composeEmailError,
    meetingUsers,
    composeEmail,
    singleMeetingError,
    meetingAttendeesLoading,
    showAttendeeEmail,
    showAttendeePhone,
    showAttendeeTitle,
    showAttendeeOrganization,
  } = useAttendeesV5({ meetingId });

  const buildRoute = useBuildClientRoute();
  const userProfileOnPress = (user: any) => {
    navigate(buildRoute(`/profile/${user.id}`), {
      state: { from: location },
    });
  };

  if (meetingAttendeesLoading) return <ActivityIndicator size="large" />;

  return (
    <CollapsableContents icon={faUsers} title={"Attendees"}>
      <DisplayErrorMessagesIfPresent
        errors={[
          { name: "Meeting Attendee", val: meetingAttendeeError },
          { name: "Composing Email", val: composeEmailError },
          { name: "Meeting Leads", val: singleMeetingError },
        ]}
      />
      <AttendeesScreenUI
        meetingUsers={meetingUsers}
        composeEmail={composeEmail}
        userProfileOnPress={userProfileOnPress}
        showAttendeeEmail={showAttendeeEmail}
        showAttendeePhone={showAttendeePhone}
        showAttendeeTitle={showAttendeeTitle}
        showAttendeeOrganization={showAttendeeOrganization}
      />
    </CollapsableContents>
  );
};
