import { type USState, usStates } from "@constituentvoice/cv-elements/web";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import { faCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { faFilter } from "@fortawesome/pro-solid-svg-icons/faFilter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useButton } from "@react-aria/button";
import type { AriaListBoxProps } from "@react-aria/listbox";
import { useOverlayTrigger } from "@react-aria/overlays";
import { Item } from "@react-stately/collections";
import { useListState } from "@react-stately/list";
import {
  OverlayTriggerProps,
  useOverlayTriggerState,
} from "@react-stately/overlays";
import * as React from "react";

import { useAnchorElementStyles } from "../../../../../hooks/use-anchor-element-styles";

import { ListBox } from "./ListBox";
import { StateFilterPopover } from "./Popover.styles";
import {
  IconContainer,
  OpenIcon,
  StateFilterButton,
  StateFilterContainer,
  StateFilterValue,
  StateFilterView,
} from "./StateFilter.styles";

type BaseStateFilterProps = AriaListBoxProps<USState> & OverlayTriggerProps;

const BaseStateFilter = (props: BaseStateFilterProps) => {
  const state = useOverlayTriggerState(props);
  const listState = useListState(props);
  const { selectedKeys } = listState.selectionManager;
  const [firstSelectedKey] = selectedKeys;

  // Get props for child elements from useSelect
  const ref = React.useRef<HTMLButtonElement>(null);

  const { triggerProps, overlayProps } = useOverlayTrigger(
    { type: "listbox" },
    state,
    ref,
  );

  // Get props for the button based on the trigger props from useSelect
  const { buttonProps } = useButton(triggerProps, ref);

  const stateFilterButtonRef = React.useRef<HTMLDivElement>(null);

  const popOverStyle = useAnchorElementStyles(
    state.isOpen,
    stateFilterButtonRef,
  );

  return (
    <StateFilterContainer>
      <StateFilterView
        hasSelection={Boolean(selectedKeys.size)}
        ref={stateFilterButtonRef}
      >
        <IconContainer
          onClick={() => {
            if (!selectedKeys.size) return;
            listState.selectionManager.setSelectedKeys([]);
          }}
        >
          <FontAwesomeIcon icon={selectedKeys.size ? faTimes : faFilter} />
        </IconContainer>
        <StateFilterButton {...buttonProps} ref={ref}>
          <StateFilterValue>
            {selectedKeys.size > 0
              ? `Filtered by State${
                  selectedKeys.size === 1 ? `: ${firstSelectedKey}` : "s"
                }`
              : "Filter by State"}
          </StateFilterValue>
          <IconContainer as={"div"}>
            <OpenIcon icon={faCaretDown} isOpen={state.isOpen} />
          </IconContainer>
        </StateFilterButton>
      </StateFilterView>
      {state.isOpen && (
        <StateFilterPopover
          state={state}
          triggerRef={ref}
          placement={"bottom"}
          style={popOverStyle}
          overlayProps={overlayProps}
          left={Number(popOverStyle?.left)}
        >
          <ListBox {...props} state={listState} />
        </StateFilterPopover>
      )}
    </StateFilterContainer>
  );
};

type StateFilterProps = Omit<
  BaseStateFilterProps,
  "label" | "children" | "items" | "onSelectionChange"
> & {
  onSelectionChange: React.Dispatch<React.SetStateAction<Set<string>>>;
};

export const StateFilter = ({
  onSelectionChange,
  ...props
}: StateFilterProps) => {
  return (
    <BaseStateFilter
      label={"Filter by State"}
      items={usStates}
      {...props}
      selectionMode={"multiple"}
      onSelectionChange={(keys) => {
        if (keys === "all") onSelectionChange?.(new Set());
        else onSelectionChange?.(new Set([...keys].map((it) => it.toString())));
      }}
    >
      {(item: USState) => <Item key={item.abbreviation}>{item.name}</Item>}
    </BaseStateFilter>
  );
};
