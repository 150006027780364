import styled from "styled-components";

export const EventSurveyScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const EventSurvey = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const EventSurveyScreenTitle = styled.div`
  display: flex;
  color: ${(p) => p.theme.colors.onAccent};
  background-color: ${(p) => p.theme.colors.accent};
  border-radius: 0.75rem 0.75rem 0 0;
  padding: 1rem;
  font-size: 1.25rem;
`;

export const SurveyScreenContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.5rem;
  border-right: 2px solid ${(p) => p.theme.colors.surfaceAlpha[10]};
  border-bottom: 2px solid ${(p) => p.theme.colors.surfaceAlpha[10]};
  border-left: 2px solid ${(p) => p.theme.colors.surfaceAlpha[10]};
  border-top: none;
  border-radius: 0 0 0.75rem 0.75rem;
  background-color: transparent;
  text-align: left;
`;

export const PreviouslySubmittedBanner = styled.div`
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 9px;
  background: ${(props) => props.theme.colors.positive};
`;
