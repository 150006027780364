import * as React from "react";

import { useAppTitle } from "../../hooks/use-client-title";

import { MeetingSurveyScreenViewV5 } from "./MeetingSurveyScreen-v5.view";

export const MeetingSurveyScreenView = () => {
  useAppTitle("Meeting Survey");
  return <MeetingSurveyScreenViewV5 />;
};
