import {
  CollapsableContents,
  DisplayErrorMessagesIfPresent,
  NoInfoToDisplay,
  RenderNewsView,
  getLegislatorActionsV5,
} from "@constituentvoice/cv-elements/web";
import { faNewspaper } from "@fortawesome/pro-solid-svg-icons/faNewspaper";
import { ActivityIndicator } from "react-native";

interface LegislatorNewsViewProps {
  newsDetails: Awaited<ReturnType<typeof getLegislatorActionsV5>> | undefined;
  newsLoading: boolean;
  newsDetailsError: unknown;
}

export const LegislatorNewsView = ({
  newsDetails = [],
  newsLoading,
  newsDetailsError,
}: LegislatorNewsViewProps) => {
  if (newsLoading) return <ActivityIndicator size="large" />;
  return (
    <CollapsableContents icon={faNewspaper} title={"News"}>
      <DisplayErrorMessagesIfPresent
        errors={[{ name: "News Details Error", val: newsDetailsError }]}
      />
      {newsDetails.length > 0 ? (
        <RenderNewsView
          newsDetails={newsDetails}
          newsLoading={newsLoading}
          newsDetailsError={newsDetailsError}
        />
      ) : (
        <NoInfoToDisplay />
      )}
    </CollapsableContents>
  );
};
