import {
  DirectoryHomeScreenUI,
  getEventUsersV5,
  useDirectoryHomeV5,
} from "@constituentvoice/cv-elements/web";
import * as React from "react";
import { ActivityIndicator } from "react-native";
import { useLocation, useNavigate } from "react-router-dom";

import { useBuildClientRoute } from "../../hooks/use-build-client-route";

export const DirectoryHomeScreenViewV5 = () => {
  const {
    currentUser,
    searchTerm,
    setSearchTerm,
    refetchEventUsers,
    loading,
    filteredUsers,
  } = useDirectoryHomeV5();

  const navigate = useNavigate();
  const location = useLocation();
  const buildRoute = useBuildClientRoute();

  const createChatOnPress = () => {
    // navigation.navigate("DirectoryUserChatScreen", {
    //   user: currentUser,
    // });
  };

  const currentUserProfileOnPress = () => {
    navigate(buildRoute(`/profile/${currentUser?.id}`), {
      state: { from: location },
    });
  };

  const userOnPress = (
    user: Awaited<ReturnType<typeof getEventUsersV5>>[0],
  ) => {
    navigate(buildRoute(`/profile/${user.id}`), { state: { from: location } });
  };

  // useRefreshOnFocus(refetchEventUsers);

  if (loading) {
    return <ActivityIndicator accessibilityLabel="Loading..." size="large" />;
  }

  return (
    <DirectoryHomeScreenUI
      currentUser={currentUser}
      searchTerm={searchTerm}
      filteredUsers={filteredUsers}
      refetchEventUsers={refetchEventUsers as never}
      setSearchTerm={setSearchTerm}
      currentUserProfileOnPress={currentUserProfileOnPress}
      createChatOnPress={createChatOnPress}
      userOnPress={userOnPress}
      defaultUserAvatar={"/images/default-avatar.png"}
    />
  );
};
