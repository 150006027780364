import { Heading, View } from "@aws-amplify/ui-react";
import {
  LoginClientHeader,
  getSelectedClientLambda,
} from "@constituentvoice/cv-elements/web";

type Client = Exclude<
  Awaited<ReturnType<typeof getSelectedClientLambda>>,
  null
>;

interface ResetPasswordProps {
  client: Client;
  supportOnPress: () => void;
}

export const ResetPassword = (props: ResetPasswordProps) => ({
  Header() {
    return (
      <View>
        <LoginClientHeader client={props.client} />
        <Heading level={3}>Reset Your Password</Heading>
      </View>
    );
  },
});
