import {
  clearClient,
  refetchToken,
  setClient,
  useAppDispatch,
  useAppSelector,
} from "@constituentvoice/cv-elements/web";
import { useEffect, useLayoutEffect, useMemo } from "react";
import * as React from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { useGetGroups } from "../hooks/use-get-groups";
import { useUrlClientId } from "../hooks/use-url-client-id";

export const ClientListLayout = () => {
  const { data: groups, isFetching, isInitialLoading, error } = useGetGroups();
  const urlClientId = useUrlClientId();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const client = useAppSelector((state) => state.client.client);
  const env = useAppSelector((state) => state.env.env);

  useLayoutEffect(() => {
    // Clear the client on load
    dispatch(clearClient());
  }, [dispatch]);

  useEffect(() => {
    if (error || isInitialLoading) return;

    if (!groups) {
      // User has no groups
      dispatch(clearClient());
      navigate("/");
      return;
    }

    const matchedGroup = groups.find(
      (group) => group.serviceCode === urlClientId,
    );

    if (!matchedGroup) {
      // User has typed invalid URL
      dispatch(clearClient());
      navigate("/");
      return;
    }

    void dispatch(setClient(matchedGroup));
    // Check if the user has logged in already
    void dispatch(refetchToken());
  }, [
    dispatch,
    error,
    groups,
    isFetching,
    isInitialLoading,
    navigate,
    urlClientId,
  ]);

  if (!env) return <p>Loading env...</p>;

  if (error) {
    return <p>There was an error</p>;
  }

  if (isInitialLoading) {
    return <p>Loading groups...</p>;
  }

  if (!client) {
    return <p>Loading client...</p>;
  }

  return <Outlet />;
};
