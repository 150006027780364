import {
  LegislatorsDetailsScreenUI,
  getLegislatorEventBillsV5,
  useLegislatorDetailV5,
  useLegislatorNotesV5,
  useRenderBillsV5,
  useRenderCommitteesV5,
  useRenderNewsV5,
} from "@constituentvoice/cv-elements/web";
import * as React from "react";
import { ActivityIndicator } from "react-native";
import { useNavigate, useParams } from "react-router-dom";

import { useBuildClientRoute } from "../../../hooks/use-build-client-route";
import { useAppTitle } from "../../../hooks/use-client-title";

import { LegislatorBillsView } from "./components/LegislatorBillsView";
import { LegislatorCommitteesView } from "./components/LegislatorCommitteesView";
import { LegislatorNewsView } from "./components/LegislatorNewsView";

export interface LegislatorsDetailsProps {
  legislatorId: number | string;
}

export const LegislatorsDetailsScreenViewV5 = () => {
  const { legislatorId } = useParams<
    keyof LegislatorsDetailsProps
  >() as LegislatorsDetailsProps;

  const {
    currentUser,
    selectedTab,
    setSelectedTab,
    modalVisible,
    setModalVisible,
    legislator,
    legislatorLoading,
  } = useLegislatorDetailV5({ userId: legislatorId });

  useAppTitle(legislator?.fullName || "Legislator Details");

  const { newsDetails, newsLoading, newsDetailsError } = useRenderNewsV5({
    userId: legislatorId,
  });

  const { committeeDetails, committeesLoading, committeeDetailsError } =
    useRenderCommitteesV5({ userId: legislatorId });

  const navigate = useNavigate();
  const buildRoute = useBuildClientRoute();

  const onPressBillDetailsNav = (
    bill: Awaited<ReturnType<typeof getLegislatorEventBillsV5>>[0] | undefined,
  ) => {
    if (!bill) return;
    navigate(buildRoute(`/event-bills/${bill?.billId}`));
  };

  const { billDetails, billsLoading, billDetailsError, refetchBillDetails } =
    useRenderBillsV5({
      userId: legislatorId,
    });

  const noteProps = useLegislatorNotesV5({ userId: legislatorId });

  if (legislatorLoading) {
    return <ActivityIndicator size="large" accessibilityLabel="Loading..." />;
  }

  return (
    <LegislatorsDetailsScreenUI
      currentUser={currentUser}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      modalVisible={modalVisible}
      setModalVisible={setModalVisible}
      newsDetails={newsDetails}
      newsLoading={newsLoading}
      newsDetailsError={newsDetailsError}
      committeeDetails={committeeDetails}
      committeesLoading={committeesLoading}
      committeeDetailsError={committeeDetailsError}
      onPressBillDetailsNav={onPressBillDetailsNav}
      billDetails={billDetails}
      billsLoading={billsLoading}
      billDetailsError={billDetailsError}
      noteProps={noteProps}
      legislator={legislator}
      billsElement={
        <LegislatorBillsView
          onPressBillDetailsNav={onPressBillDetailsNav}
          billDetails={billDetails}
          billsLoading={billsLoading}
          billDetailsError={billDetailsError}
          legislatorId={legislatorId}
        />
      }
      newsElement={
        <LegislatorNewsView
          newsDetails={newsDetails}
          newsLoading={newsLoading}
          newsDetailsError={newsDetailsError}
        />
      }
      committeesElement={
        <LegislatorCommitteesView
          committeeDetails={committeeDetails}
          committeesLoading={committeesLoading}
          committeeDetailsError={committeeDetailsError}
        />
      }
    />
  );
};
