import {
  DisplayErrorMessagesIfPresent,
  Modal,
  NoSurveyText,
  NoSurveyView,
  SurveyScreenUIV5,
  sendEventSurveyResponseV5,
  useAppSelector,
  useSurveyScreenV5,
} from "@constituentvoice/cv-elements/web";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons/faInfoCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useOverlayTriggerState } from "@react-stately/overlays";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import * as React from "react";
import { ActivityIndicator, View } from "react-native";
import { useNavigate } from "react-router-dom";

import { AlertDialog } from "../../components/AlertDialog/AlertDialog";
import { usePreventNavigate } from "../../hooks/use-prevent-navigate";

import {
  EventSurvey,
  EventSurveyScreenContainer,
  EventSurveyScreenTitle,
  PreviouslySubmittedBanner,
  SurveyScreenContent,
} from "./EventSurveyScreen.styles";

export const EventSurveyScreenViewV5 = () => {
  const cancelModalState = useOverlayTriggerState({});
  const submitSuccessfulModalState = useOverlayTriggerState({});
  const navigate = useNavigate();
  const currentEvent = useAppSelector(
    (state) => state.currentEvent.currentEvent,
  );
  const {
    surveyQuestionAnswers,
    survey,
    surveyError,
    surveyLoading,
    refetchSurvey,
    questionsMeta,
    previouslySubmittedDate,
    surveySent,
    networkingProps,
    isSubmitDisabled,
    isDirty,
  } = useSurveyScreenV5({ type: "Event", id: String(currentEvent?.id) });

  const { mutate: submitSurvey, error: submitSurveyError } = useMutation({
    mutationFn: async () => {
      await sendEventSurveyResponseV5({
        ...networkingProps,
        eventId: String(currentEvent?.id),
        surveyId: String(survey?.survey.id),
        body: surveyQuestionAnswers,
      });
      submitSuccessfulModalState.setOpen(true);
      void refetchSurvey();
    },
  });

  const handleCancelButton = () => {
    cancelModalState.setOpen(true);
  };

  usePreventNavigate(
    () => isDirty && !cancelModalState.isOpen,
    "Are you sure you want to leave this page?",
  );

  if (
    surveyError &&
    (surveyError as AxiosError).response &&
    (surveyError as AxiosError)?.response?.status === 404
  ) {
    return (
      <NoSurveyView>
        <NoSurveyText>There is no survey for this event.</NoSurveyText>
      </NoSurveyView>
    );
  }

  return (
    <>
      <DisplayErrorMessagesIfPresent
        errors={[
          { name: "Survey", val: surveyError },
          { name: "Submit Survey Error", val: submitSurveyError },
        ]}
      />
      {surveyLoading && (
        <View style={{ alignContent: "center", justifyContent: "center" }}>
          <ActivityIndicator accessibilityLabel="Loading..." size="large" />
        </View>
      )}
      {!surveyLoading && !!survey?.survey && (
        <EventSurveyScreenContainer>
          {previouslySubmittedDate && (
            <PreviouslySubmittedBanner>
              <FontAwesomeIcon icon={faInfoCircle} />
              You previously submitted this form on{" "}
              {`${previouslySubmittedDate}`}
            </PreviouslySubmittedBanner>
          )}
          <EventSurvey>
            <EventSurveyScreenTitle>Event Feedback</EventSurveyScreenTitle>
            <SurveyScreenContent>
              <SurveyScreenUIV5
                survey={survey}
                surveyTitle={survey.survey.title}
                surveyQuestions={survey.survey.questions}
                questionAnswers={surveyQuestionAnswers}
                questionsMeta={questionsMeta}
                submitSurvey={submitSurvey}
                handleCancelButton={handleCancelButton}
                surveySent={surveySent}
                isSubmitDisabled={isSubmitDisabled}
                previouslySubmittedDate={previouslySubmittedDate}
                onEditResponsesPress={() => {}}
              />
            </SurveyScreenContent>
          </EventSurvey>
        </EventSurveyScreenContainer>
      )}
      {
        <Modal state={cancelModalState}>
          <AlertDialog
            title={"Unsaved Changes"}
            confirmLabel={"Leave Page"}
            closeLabel={"Cancel"}
            onClose={() => cancelModalState.setOpen(false)}
            onConfirm={() => navigate(-1)}
          >
            Are you sure you want to leave this page? <br />
            <br />
            Changes you made will not be saved.
          </AlertDialog>
        </Modal>
      }
      {
        <Modal state={submitSuccessfulModalState}>
          <AlertDialog
            closeLabel={"Ok"}
            onClose={() => submitSuccessfulModalState.setOpen(false)}
          >
            Survey submitted successfully!
          </AlertDialog>
        </Modal>
      }
    </>
  );
};
