import {
  DisplayErrorMessagesIfPresent,
  LobbyDaySelectionScreenUIWebV5,
  useLobbyDaySelectionV5,
} from "@constituentvoice/cv-elements/web";
import { ActivityIndicator } from "react-native";
import { useNavigate } from "react-router-dom";

import { useBuildClientRoute } from "../../hooks/use-build-client-route";
import { useAppTitle } from "../../hooks/use-client-title";

export const LobbyDaySelectionScreenV5 = () => {
  const navigate = useNavigate();
  const buildRoute = useBuildClientRoute();
  const {
    // refetch,
    eventsLoading,
    eventDataLoading,
    eventsError,
    currentEvent,
    userEvents,
    mutateCurrentEvent,
  } = useLobbyDaySelectionV5();

  useAppTitle("Your Events");

  if (eventsLoading || eventDataLoading) {
    return <ActivityIndicator accessibilityLabel="Loading..." size="large" />;
  }

  return (
    <>
      <DisplayErrorMessagesIfPresent
        errors={[{ name: "Fetching Events", val: eventsError }]}
      />
      <LobbyDaySelectionScreenUIWebV5
        currentEvent={currentEvent!}
        eventsLoading={eventsLoading}
        eventDataLoading={eventDataLoading}
        userEvents={userEvents}
        mutateCurrentEvent={mutateCurrentEvent}
        onCurrentEventUpdated={() => {
          navigate(buildRoute("/"));
        }}
      />
    </>
  );
};
