import { Authenticator } from "@aws-amplify/ui-react";
import {
  EnvironmentalVars,
  getBaseUrlV5,
  getSelectedClientLambda,
  resetUserStateForgotPasswordV5,
  useAPIVersion,
} from "@constituentvoice/cv-elements/web";
import { useMemo } from "react";

import { ResetPassword } from "./components/ResetPassword";
import { SignIn } from "./components/SignIn";
import { SignUp } from "./components/SignUp";

import "@aws-amplify/ui-react/styles.css";
import { Auth } from "aws-amplify";
import { defaultServices } from "@aws-amplify/ui/dist/types/machines/authenticator/defaultServices";

import { AuthContainer } from "./LoginScreen.styles";

type Client = Exclude<
  Awaited<ReturnType<typeof getSelectedClientLambda>>,
  null
>;

interface ILoginScreenCognitoUI {
  client: Client;
  onSignedIn: () => void;
  supportOnPress: () => void;
  env: EnvironmentalVars;
}

const formFields = {
  signIn: {
    username: {
      labelHidden: false,
      placeholder: "Enter Your Email Here",
      isRequired: true,
      label: "Email:",
      autocomplete: "off",
    },
    password: {
      labelHidden: false,
      placeholder: "Enter Your Password Here",
      isRequired: true,
      label: "Password:",
      autocomplete: "off",
    },
  },
};

export const LoginScreenUI = ({
  client,
  onSignedIn,
  supportOnPress,
  env,
}: ILoginScreenCognitoUI) => {
  const components = useMemo(
    () => ({
      SignIn: SignIn({ client, supportOnPress }),
      SignUp: SignUp({ client, supportOnPress }),
      ResetPassword: ResetPassword({ client, supportOnPress }),
    }),
    [client, supportOnPress],
  );

  const services = useMemo(
    () =>
      ({
        async handleForgotPassword(username: string) {
          await resetUserStateForgotPasswordV5({
            env: env.ENVIRONMENT,
            serviceCode: client.serviceCode,
            baseUrl: getBaseUrlV5(client, env),
            body: {
              username: username,
              clientOrganizationId: "" + client.id,
            },
          });

          // return the forgotPassword completion regardless
          return Auth.forgotPassword(username, {
            email: username,
            clientOrganizationId: "" + client.id,
          });
        },
        async handleSignIn(form) {
          return Auth.signIn(form.username?.toLowerCase(), form.password, {
            email: form.username,
            clientOrganizationId: "" + client.id,
          });
        },
        async handleSignUp(formData: { username: string; password: string }) {
          return Auth.signUp({
            ...formData,
            autoSignIn: {
              enabled: true,
              clientMetaData: {
                email: formData.username,
                clientOrganizationId: "" + client.id,
              },
            },
            validationData: {
              email: formData.username,
              clientOrganizationId: "" + client.id,
            },
          });
        },
        async handleConfirmSignIn({ user, code, mfaType }) {
          return Auth.confirmSignIn(
            user,
            code,
            // Cognito's types are bad and they should feel bad
            mfaType as never,
            {
              email: user,
              clientOrganizationId: "" + client.id,
            },
          );
        },
        async handleConfirmSignUp({ username, code }) {
          return Auth.confirmSignUp(username, code, {
            clientMetadata: {
              email: username,
              clientOrganizationId: "" + client.id,
            },
          });
        },
        async handleForgotPasswordSubmit({ username, code, password }) {
          return Auth.forgotPasswordSubmit(username, code, password, {
            email: username,
            clientOrganizationId: "" + client.id,
          });
        },
      }) satisfies Partial<typeof defaultServices>,
    [client, env],
  );

  return (
    <AuthContainer>
      <Authenticator
        formFields={formFields}
        initialState={"signIn"}
        loginMechanisms={["email"]}
        components={components}
        services={services}
      >
        {() => {
          onSignedIn();
          return <p>Loading...</p>;
        }}
      </Authenticator>
    </AuthContainer>
  );
};
