import {
  CollapsableContents,
  DisplayErrorMessagesIfPresent,
  ResourcesUI,
  useMeetingDocumentsV5,
} from "@constituentvoice/cv-elements/web";
import { faFolder } from "@fortawesome/pro-solid-svg-icons/faFolder";
import { ActivityIndicator, RefreshControl } from "react-native";

interface MeetingDocumentsViewV5Props {
  meetingId: string;
}

export function MeetingDocumentsViewV5({
  meetingId,
}: MeetingDocumentsViewV5Props) {
  const {
    refetch,
    isLoading,
    isRefetching,
    openDocumentError,
    documentsError,
    allDocs,
    openDocument,
  } = useMeetingDocumentsV5({ meetingId });

  if (isLoading) {
    return <ActivityIndicator accessibilityLabel="Loading..." size="large" />;
  }

  return (
    <CollapsableContents icon={faFolder} title={"Documents & Resources"}>
      <DisplayErrorMessagesIfPresent
        errors={[
          { name: "Meeting Documents", val: documentsError },
          { name: "Opening Document", val: openDocumentError },
        ]}
      />
      <ResourcesUI
        documents={allDocs}
        openDocument={openDocument}
        refreshControl={
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          <RefreshControl refreshing={isRefetching} onRefresh={refetch} />
        }
      />
    </CollapsableContents>
  );
}
