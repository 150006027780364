import { CVButton } from "@constituentvoice/cv-elements/web";
import type { AriaDialogProps } from "@react-aria/dialog";
import { useDialog } from "@react-aria/dialog";
import React from "react";

import {
  AlertDialogButtonsContainer,
  AlertDialogContainer,
  AlertDialogDate,
  AlertDialogText,
  AlertDialogTextsContainer,
  AlertDialogTitle,
} from "./AlertDialog.styles";

interface AlertDialogProps extends AriaDialogProps {
  children: React.ReactNode;
  title?: string;
  date?: string;
  confirmLabel?: string;
  closeLabel?: string;
  onClose?: () => void;
  onConfirm?: () => void;
}

export function AlertDialog(props: AlertDialogProps) {
  const { children, confirmLabel, closeLabel, onClose, onConfirm } = props;

  const ref = React.useRef(null);
  const { dialogProps, titleProps } = useDialog(
    {
      ...props,
      role: "alertdialog",
    },
    ref,
  );

  return (
    <AlertDialogContainer {...dialogProps} ref={ref}>
      <AlertDialogTextsContainer>
        {props.date ? <AlertDialogDate>{props.date}</AlertDialogDate> : null}
        <AlertDialogTitle {...titleProps}>{props.title}</AlertDialogTitle>
        <AlertDialogText>{children}</AlertDialogText>
      </AlertDialogTextsContainer>
      {closeLabel || confirmLabel ? (
        <AlertDialogButtonsContainer>
          {closeLabel && <CVButton onClick={onClose}>{closeLabel}</CVButton>}
          {confirmLabel && (
            <CVButton variant={"primary"} onClick={onConfirm}>
              {confirmLabel}
            </CVButton>
          )}
        </AlertDialogButtonsContainer>
      ) : null}
    </AlertDialogContainer>
  );
}
