import type { openComposer as rnElOpenComposer } from "../node_modules/react-native-email-link/index";

export const openComposer = (
  ...propsArr: Parameters<typeof rnElOpenComposer>
) => {
  const props = propsArr[0]!;

  const to = props.to || "";

  let mailToString = "mailto:" + encodeURIComponent(to);

  const prefix = () => {
    if (mailToString.includes("?")) {
      mailToString += "&";
    } else {
      mailToString += "?";
    }
  };

  if (props.cc) {
    prefix();
    mailToString += "cc=" + encodeURIComponent(props.cc);
  }

  if (props.bcc) {
    prefix();
    mailToString += "bcc=" + encodeURIComponent(props.bcc);
  }

  if (props.subject) {
    prefix();
    mailToString += "subject=" + encodeURIComponent(props.subject);
  }

  if (props.body) {
    prefix();
    mailToString += "body=" + encodeURIComponent(props.body);
  }

  window.open(mailToString, "_self");
};
