import { useMemo } from "react";
import type { RefObject } from "react";

export const useAnchorElementStyles = (
  isOpen: boolean,
  anchorRef: RefObject<HTMLElement>,
) =>
  useMemo<React.CSSProperties>(() => {
    if (!isOpen) return {};
    const view = anchorRef.current;
    if (!view) return {};
    const style = window.getComputedStyle(view);
    const dimensions = view.getBoundingClientRect();
    return {
      left: view.offsetLeft + parseFloat(style.paddingLeft),
      width: dimensions.width,
    };
  }, [isOpen, anchorRef]);
