import * as React from "react";

import { MeetingDocumentsViewV5 } from "./MeetingDocuments-v5.view";

interface MeetingDocumentsViewProps {
  meetingId: string;
}

export function MeetingDocumentsView(props: MeetingDocumentsViewProps) {
  return <MeetingDocumentsViewV5 {...props} />;
}
