import styled from "styled-components";

export const ListBoxLabel = styled.label`
  display: none;
  visibility: hidden;
`;

export const List = styled.ul`
  max-height: 300px;
  overflow: auto;
  list-style: none;
  padding: 0;
  margin: 0;
  outline: none;
  width: 100%;
`;

interface ListItemProps {
  isFocused?: boolean;
  isSelected?: boolean;
}

export const ListItem = styled.li<ListItemProps>`
  background: ${(props) => (props.isFocused ? props.theme.colors.accent : "")};
  color: ${(props) =>
    props.isFocused
      ? "white"
      : props.isSelected
        ? props.theme.colors.accent
        : "#333"};
  font-size: 0.875rem;
  font-weight: ${(props) => (props.isSelected ? "600" : "500")};
  padding: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  outline: none;
  min-height: 3rem;
`;

export const ItemContent = styled.div`
  display: flex;
  align-items: center;
`;
