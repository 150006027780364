import {
  AllLegislatorsScreenUI,
  DisplayErrorMessagesIfPresent,
  USState,
  getAllLegislatorsV5,
} from "@constituentvoice/cv-elements/web";
import * as React from "react";
import { useMemo } from "react";
import { ActivityIndicator } from "react-native";
import { useNavigate } from "react-router-dom";

import { useBuildClientRoute } from "../../../hooks/use-build-client-route";

export interface AllLegislatorsScreenViewWebProps {
  searchTerm?: string;
  setSearchTerm?: React.Dispatch<React.SetStateAction<string>>;
  stateFilter?: Set<USState["abbreviation"]>;
  allLegislators: Awaited<ReturnType<typeof getAllLegislatorsV5>>;
  allLegislatorsLoading: boolean;
  allLegislatorsRefetching: boolean;
  refetchAllLegislators: () => void;
  allLegislatorsError: unknown;
}

export const AllLegislatorsScreenView = ({
  searchTerm,
  setSearchTerm,
  stateFilter,
  allLegislators,
  allLegislatorsLoading,
  allLegislatorsRefetching,
  refetchAllLegislators,
  allLegislatorsError,
}: AllLegislatorsScreenViewWebProps) => {
  const navigate = useNavigate();
  const buildRoute = useBuildClientRoute();

  const onLegislatorItemPressed = (
    user: Awaited<ReturnType<typeof getAllLegislatorsV5>>[0],
  ) => {
    navigate(buildRoute(`/legislators-home/${user?.id}`));
  };

  const isFetchingSearchQuery = !!searchTerm && allLegislatorsRefetching;

  const loadingIndicator = useMemo(
    () =>
      allLegislatorsLoading || isFetchingSearchQuery ? (
        <ActivityIndicator size="large" />
      ) : null,
    [allLegislatorsLoading, isFetchingSearchQuery],
  );

  const filteredLegislators = React.useMemo(() => {
    if (loadingIndicator) return [];
    if (!allLegislators || !allLegislators?.length) return [];

    return allLegislators
      .filter((legislator: (typeof allLegislators)[number]) => {
        if (!searchTerm || !searchTerm?.length) return true;
        return `${legislator.firstName ?? ""} ${legislator.lastName ?? ""}`
          .toLowerCase()
          .includes(searchTerm.trim().toLowerCase());
      })
      .filter((legislator: (typeof allLegislators)[number]) => {
        if (!stateFilter || !stateFilter?.size) return true;
        return Array.from(stateFilter).some((state) => {
          const legislatorState = legislator.stateAbbreviation;
          return legislatorState.toLowerCase().includes(state.toLowerCase());
        });
      });
  }, [allLegislators, loadingIndicator, searchTerm, stateFilter]);

  return (
    <>
      <DisplayErrorMessagesIfPresent
        errors={[{ name: "Fetching Legislators", val: allLegislatorsError }]}
      />
      <AllLegislatorsScreenUI
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        refetchAllLegislators={refetchAllLegislators}
        allLegislators={filteredLegislators}
        userOnPress={onLegislatorItemPressed}
        showUserList={!loadingIndicator}
      >
        {loadingIndicator}
      </AllLegislatorsScreenUI>
    </>
  );
};
