import { listClientsLambda, useTheme } from "@constituentvoice/cv-elements/web";
import {
  ActivityIndicator,
  Image,
  Modal,
  Pressable,
  StyleSheet,
  Text,
  View,
} from "react-native";
import { SearchBar } from "react-native-elements";
import { ScrollView } from "react-native-gesture-handler";

interface ChooseYourGroupUIProps {
  selectClient: (
    clientData: Awaited<ReturnType<typeof listClientsLambda>>[number],
  ) => void;
  loading: boolean;
  searchTerm: string;
  setSearchTerm: (text: string) => void;
  filteredGroups: Awaited<ReturnType<typeof listClientsLambda>>;
}

export const ChooseYourGroupUI = ({
  selectClient,
  loading,
  searchTerm,
  setSearchTerm,
  filteredGroups,
}: ChooseYourGroupUIProps) => {
  const theme = useTheme();

  return (
    <>
      <Modal
        animationType="slide"
        transparent={true}
        presentationStyle="overFullScreen"
        visible={true}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <View
              style={{
                flexShrink: 1,
                width: "100%",
                paddingBottom: 5,
                borderBottomColor: theme.colors.surfaceAlpha[10],
                borderBottomWidth: StyleSheet.hairlineWidth,
              }}
            >
              <Text style={styles.modalText}>Choose Your Organization</Text>
            </View>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/*
                   // @ts-ignore */}
            <SearchBar
              inputContainerStyle={{
                flexGrow: 1,
                backgroundColor: theme.colors.background,
              }}
              inputStyle={{ color: theme.colors.onPrimary }}
              platform="default"
              round
              containerStyle={{
                flexShrink: 1,
                width: "100%",
                backgroundColor: "white",
              }}
              onChangeText={((text: string) => setSearchTerm(text)) as never}
              value={searchTerm}
              onClear={() => setSearchTerm("")}
              autoComplete={undefined}
              showLoading={false}
              onCancel={() => setSearchTerm("")}
              cancelButtonTitle={""}
              showCancel={true}
              lightTheme={true}
              autoCorrect={false}
            />
            <ScrollView
              style={{
                flex: 1,
                width: "100%",
                margin: 10,
                paddingHorizontal: 10,
              }}
            >
              <View style={{}}>
                {loading && <ActivityIndicator size="large" />}
                {filteredGroups.map((client) => {
                  return (
                    <Pressable
                      key={client.serviceCode}
                      style={{ flex: 1 }}
                      onPress={() => selectClient(client)}
                    >
                      <View
                        style={{
                          flexDirection: "row",
                          alignContent: "center",
                          padding: 16,
                        }}
                      >
                        <Image
                          style={{
                            height: "auto",
                            width: "100%",
                            objectFit: "contain",
                            resizeMode: "contain",
                            maxWidth: 144,
                            aspectRatio: 16 / 9,
                            borderRadius: 8,
                            borderColor: theme.colors.surfaceAlpha[10],
                            borderWidth: 2,
                            padding: 8,
                          }}
                          source={{
                            uri: client.logoUrl ?? "/images/capitol.png",
                          }}
                        />
                        <Text
                          style={{
                            flex: 1,
                            paddingLeft: 25,
                            alignSelf: "center",
                            fontWeight: "bold",
                          }}
                        >
                          {client.vendor}
                        </Text>
                      </View>
                    </Pressable>
                  );
                })}
              </View>
            </ScrollView>
          </View>
        </View>
      </Modal>
    </>
  );
};

const styles = StyleSheet.create({
  modalText: {
    fontSize: 20,
    fontFamily: "Roboto-Bold",
    textAlign: "center",
    color: "black",
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalView: {
    flex: 1,
    width: "90%",
    marginVertical: 22,
    //margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 5,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
});
