import {
  ActionCenterHomeUI,
  useActionCenterHomeV5,
} from "@constituentvoice/cv-elements/web";
import * as React from "react";
import { ActivityIndicator } from "react-native";

export const ActionCenterHomeViewV5 = () => {
  // const navigation = useNavigation<NativeStackNavigationProp<any, any>>();
  const { actionAlerts, isLoading, refetch } = useActionCenterHomeV5();

  // useRefreshOnFocus(refetch);

  const ActionAlertButtonPress = () => {
    // navigation.navigate("ACDetail", { actionAlert: alert });
  };

  if (isLoading) {
    return <ActivityIndicator accessibilityLabel="Loading..." />;
  }

  return (
    <ActionCenterHomeUI
      actionAlerts={actionAlerts as never}
      ActionAlertButtonPress={ActionAlertButtonPress}
    />
  );
};
