import styled from "styled-components";

export const AuthContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 512px;
  margin: 0 auto;
  padding: 3rem 1rem;
`;
