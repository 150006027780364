import { Modal } from "@constituentvoice/cv-elements/web";
import type { OverlayTriggerState } from "@react-stately/overlays";

import { AlertDialog } from "../../../components/AlertDialog/AlertDialog";

interface JoinMeetingTimeAlertProps {
  joinedTooEarlyAlertState: OverlayTriggerState;
  joinedTooLateAlertState: OverlayTriggerState;
  joinMeetingWait?: number;
}

export const JoinMeetingTimeAlert = (props: JoinMeetingTimeAlertProps) => {
  const {
    joinedTooEarlyAlertState,
    joinedTooLateAlertState,
    joinMeetingWait = 10,
  } = props;
  return (
    <>
      <Modal state={joinedTooEarlyAlertState}>
        <AlertDialog
          title={"Meeting has not begun"}
          closeLabel={"Close"}
          onClose={() => joinedTooEarlyAlertState.setOpen(false)}
        >
          This meeting has not begun yet. Please click the "Join Virtual
          Meeting" button again within {joinMeetingWait} minutes of the start
          time.
        </AlertDialog>
      </Modal>
      <Modal state={joinedTooLateAlertState}>
        <AlertDialog
          title={"Meeting has ended"}
          closeLabel={"Close"}
          onClose={() => joinedTooLateAlertState.setOpen(false)}
        >
          This meeting has already ended. If you feel this is an error, please
          contact support.
        </AlertDialog>
      </Modal>
    </>
  );
};
