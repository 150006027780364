import {
  DisplayErrorMessagesIfPresent,
  JoinMeetingTimeResult,
  MeetingDetailScreenUI,
  getLegislatorV5,
  useMeetingDetailV5,
  useMeetingNotesV5,
} from "@constituentvoice/cv-elements/web";
import { useOverlayTriggerState } from "@react-stately/overlays";
import { useMutation } from "@tanstack/react-query";
import * as React from "react";
import { useCallback, useEffect, useMemo } from "react";
import {
  ActivityIndicator,
  Linking,
  Platform,
  RefreshControl,
} from "react-native";
import { SheetManager } from "react-native-actions-sheet";
import { useNavigate, useParams } from "react-router-dom";

import { cvAppSettings } from "../../../app.cv.config";
import { useBuildClientRoute } from "../../hooks/use-build-client-route";
import { useAppTitle } from "../../hooks/use-client-title";
import { AttendeesScreenView } from "../Attendees/AttendeesScreen.view";
import { LegislatorBillsScreenView } from "../LegislatorBillsScreen";
import { MeetingDetailShareView } from "../MeetingDetailShare/MeetingDetailShare.view";
import { TalkingPointsScreenView } from "../TalkingPointsScreen/TalkingPointsScreen.view";

import { JoinMeetingTimeAlert } from "./components/JoinMeetingTimeAlert";
import { MeetingDocumentsView } from "./Subscreens/EventDocuments";

export interface IRouteMeetingDetailV5 {
  meetingId: number | string;
}

export const MeetingDetailScreenViewV5 = () => {
  const joinedTooEarlyAlertState = useOverlayTriggerState({});
  const joinedTooLateAlertState = useOverlayTriggerState({});

  const { meetingId } = useParams<
    keyof IRouteMeetingDetailV5
  >() as IRouteMeetingDetailV5;
  const navigate = useNavigate();

  const {
    currentUser,
    tz,
    formattedDate,
    formattedDateDeviceTime,
    phoneNumber,
    meetingWithString,
    showWarningTz,
    attended,
    showToast,
    userCheckedIn,
    checkedInLoading,
    checkIn,
    checkInError,
    changeAttendance,
    changeAttendanceError,
    composeEmail,
    composeEmailError,
    composeEmailWeb,
    composeEmailWebError,
    currentEvent,
    visible,
    setVisible,
    toastText,
    setToastText,
    modalVisible,
    setModalVisible,
    thankYouNoteModalVisible,
    setThankYouNoteModalVisible,
    pressed,
    setPressed,
    pressedStaff,
    setPressedStaff,
    note,
    refetchNote,
    isRefetchingNote,
    attendedData,
    refetchAttended,
    isRefetchingAttended,
    survey,
    refetchReport,
    isRefetchingReport,
    reportLoading,
    bills,
    refetchBills,
    isRefetchingBills,
    billsLoading,
    meeting,
    meetingIsLoading,
    meetingDate,
    joinMeetingWait,
    meetingWithTos,
    isLocationEnabled,
    isLoadingGeolocation,
    locationRequestError,
    geolocationError,
  } = useMeetingDetailV5({ meetingId: meetingId });

  useAppTitle(formattedDate);

  const refetch = useCallback(async () => {
    await Promise.all([
      refetchNote(),
      refetchAttended(),
      refetchReport(),
      refetchBills(),
    ]);
  }, [refetchAttended, refetchNote, refetchReport, refetchBills]);

  const isRefetching = useMemo(() => {
    return (
      isRefetchingNote ||
      isRefetchingAttended ||
      isRefetchingReport ||
      isRefetchingBills ||
      reportLoading ||
      billsLoading
    );
  }, [
    isRefetchingNote,
    isRefetchingAttended,
    isRefetchingReport,
    isRefetchingBills,
    reportLoading,
    billsLoading,
  ]);

  const Sheets = {
    testSheet: "test_sheet_id",
  };

  const { mutate: getDirections, error: getDirectionError } = useMutation({
    mutationFn: async () => {
      const daddr = encodeURIComponent(`${meeting?.address}`);

      if (Platform.OS === "ios") {
        await Linking.openURL(`http://maps.apple.com/?daddr=${daddr}`);
      } else {
        await Linking.openURL(`http://maps.google.com/?daddr=${daddr}`);
      }
    },
  });

  const onShare = async () => {
    await SheetManager.show(Sheets.testSheet, {
      payload: { text: "Hello World" },
    });
  };

  useEffect(() => {
    let timeout: never;
    if (visible) {
      timeout = setTimeout(() => {
        setVisible(!visible);
      }, 3000) as never;
    }
    return () => clearTimeout(timeout);
  }, [visible, setVisible]);
  const buildRoute = useBuildClientRoute();

  const legislatorBioOnPress = (
    legislator: Awaited<ReturnType<typeof getLegislatorV5>> | null | undefined,
  ) => {
    if (legislator) {
      navigate(buildRoute(`/legislators-home/${legislator.id}`));
    } else return;
  };

  const attendeesNavigationOnPress = () => {
    navigate(buildRoute(`/meeting/${meetingId}/attendees`));
  };

  const talkingPointsNavigationOnPress = () => {
    navigate(buildRoute(`/meeting/talking-points/${meetingId}`));
  };

  const documentsNavigationOnPress = () => {
    navigate(buildRoute(`/meeting/${meetingId}/documents`));
  };

  const billsNavigationOnPress = () => {
    navigate(buildRoute(`/meeting/legislator-bills/${meetingId}`));
  };

  const surveyNavigationOnPress = () => {
    navigate(buildRoute(`/meeting/${meetingId}/survey`));
  };

  const noteProps = useMeetingNotesV5({
    meetingId: meetingId,
  });

  if (meetingIsLoading) return <ActivityIndicator size="large" />;

  return (
    <>
      <DisplayErrorMessagesIfPresent
        errors={[
          { name: "Getting your Directions", val: getDirectionError },
          { name: "Launching your Email", val: composeEmailWebError },
          { name: "Changing your Attendance", val: changeAttendanceError },
          { name: "Checking In", val: checkInError },
          // { name: "Requesting Location Access", val: locationRequestError },
          // { name: "Getting Location Data", val: geolocationError },
        ]}
      />
      <MeetingDetailScreenUI
        visible={visible}
        setVisible={setVisible}
        meeting={meeting}
        setModalVisible={setModalVisible}
        thankYouNoteModalVisible={thankYouNoteModalVisible}
        setThankYouNoteModalVisible={setThankYouNoteModalVisible}
        legislatorBioOnPress={legislatorBioOnPress}
        getDirections={getDirections}
        showToast={showToast}
        userCheckedIn={userCheckedIn}
        checkedInLoading={checkedInLoading}
        checkIn={checkIn}
        attendeesNavigationOnPress={attendeesNavigationOnPress}
        talkingPointsNavigationOnPress={talkingPointsNavigationOnPress}
        documentsNavigationOnPress={documentsNavigationOnPress}
        surveyNavigationOnPress={surveyNavigationOnPress}
        survey={survey}
        composeEmail={composeEmail}
        composeEmailWeb={composeEmailWeb}
        onShare={onShare as never}
        changeAttendance={changeAttendance}
        setPressed={setPressed}
        pressed={pressed}
        setPressedStaff={setPressedStaff}
        pressedStaff={pressedStaff}
        modalVisible={modalVisible}
        toastText={toastText}
        attended={attended}
        bills={bills}
        billsNavigationOnPress={billsNavigationOnPress}
        cvAppSettings={cvAppSettings}
        meetingWithString={meetingWithString}
        phoneNumber={phoneNumber}
        subHeaderString={
          formattedDateDeviceTime !== null
            ? formattedDateDeviceTime
            : formattedDate && formattedDate !== ""
              ? formattedDate
              : undefined
        }
        subHeaderLocalTime={
          formattedDateDeviceTime !== null ? `${formattedDate}` : null
        }
        meetingDate={meetingDate.date}
        meetingTime={meetingDate.time}
        meetingLocalTime={meetingDate.localTime}
        showWarningTz={!!showWarningTz}
        currentUser={currentUser}
        tz={tz}
        refreshControl={
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          <RefreshControl refreshing={isRefetching} onRefresh={refetch} />
        }
        noteProps={noteProps}
        resourcesElement={
          <MeetingDocumentsView meetingId={meetingId as never} />
        }
        talkingPointsElement={<TalkingPointsScreenView />}
        billsElement={<LegislatorBillsScreenView />}
        attendeesElement={<AttendeesScreenView />}
        onJoinMeetingTimeResult={(result: JoinMeetingTimeResult) => {
          console.log("nwyc - join meeting result [V5]", result);
          if (result.type === "TooEarly") joinedTooEarlyAlertState.open();
          else if (result.type === "TooLate") joinedTooLateAlertState.open();
        }}
        joinMeetingWait={joinMeetingWait}
        thankYouNoteInfo={note}
        meetingWithTos={meetingWithTos}
        isLocationEnabled={isLocationEnabled}
        isLoadingGeolocation={isLoadingGeolocation}
      />
      <MeetingDetailShareView sheetID={Sheets.testSheet} meeting={meeting} />
      <JoinMeetingTimeAlert
        joinedTooEarlyAlertState={joinedTooEarlyAlertState}
        joinedTooLateAlertState={joinedTooLateAlertState}
        joinMeetingWait={joinMeetingWait}
      />
    </>
  );
};
