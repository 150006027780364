import styled from "styled-components";

import { Popover } from "../../../../../components/aria/Popover";

export const StateFilterPopover = styled(Popover)<{ left?: number }>`
  position: relative;
  top: 100%;
  z-index: 1;
  min-width: 14rem;
  border-radius: 0 0 0.75rem 0.75rem;
  overflow: hidden;
  margin-top: 0.5rem;
  margin-left: -0.75rem;
  box-shadow:
    0px 2.75px 9px 0px rgba(0, 0, 0, 0.19),
    0px 0.25px 3px 0px rgba(0, 0, 0, 0.04);
  background: white;
  left: ${(p) => p.left}px !important;
  border-top: 0.125rem solid ${(p) => p.theme.colors.accentLight};
  z-index: 99999 !important;

  &::before,
  &::after {
    content: "";
    height: 8px;
    background: white;
    width: 100%;
    position: absolute;
    top: -8px;
    z-index: 100000;
  }
`;
