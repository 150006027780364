import type { APISupportFormDataV5 } from "@constituentvoice/cv-elements/web";
import {
  DisplayErrorMessagesIfPresent,
  Modal,
  SupportScreenPublicUI,
  useSupportPublicV5,
} from "@constituentvoice/cv-elements/web";
import { useOverlayTriggerState } from "@react-stately/overlays";

import { AlertDialog } from "../../components/AlertDialog/AlertDialog";
import { useAppTitle } from "../../hooks/use-client-title";

export const SupportScreenPublicView = () => {
  const submitSuccessfulModalState = useOverlayTriggerState({});
  useAppTitle("Request Support");

  const onModalSuccess = () => {
    submitSuccessfulModalState.setOpen(true);
  };

  const { supportData, submitSupport, submitSupportError } = useSupportPublicV5(
    {
      onModalSuccess,
    },
  );

  return (
    <>
      <DisplayErrorMessagesIfPresent
        errors={[{ name: "Submitting Support Form", val: submitSupportError }]}
      />
      <SupportScreenPublicUI
        supportData={supportData}
        onSubmit={(values: APISupportFormDataV5) => {
          submitSupport(values);
        }}
      />
      <Modal state={submitSuccessfulModalState}>
        <AlertDialog
          closeLabel={"Ok"}
          onClose={() => submitSuccessfulModalState.setOpen(false)}
        >
          Support ticket submitted successfully!
        </AlertDialog>
      </Modal>
    </>
  );
};
