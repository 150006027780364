import React, {
  forwardRef,
  useImperativeHandle,
  useEffect,
  useRef,
} from "react";
import { ScrollView, View } from "react-native-web";

const ViewPager = forwardRef(function (props, ref) {
  const scrollView = useRef(null);
  const dataOrientation =
    props.orientation === "vertical"
      ? {
          "data-snap-container-vertical": true,
        }
      : {
          "data-snap-container-horizontal": true,
        };
  useImperativeHandle(ref, function () {
    return {
      snapToItem: function () {
        // console.log("scroll to ended");
        return scrollView?.current?.scrollToEnd();
      },
    };
  });
  const updated = useRef(false);
  useEffect(function () {
    if (!updated.current) {
      // console.log("updated");
      scrollView?.current?.scrollToEnd();
      updated.current = true;
    }
  });
  const elmts = React.Children.toArray(props.children);
  return (
    <ScrollView
      style={{}}
      contentContainerStyle={{
        flex: 1,
      }}
      horizontal={props.orientation !== "vertical"}
      nestedScrollEnabled={true}
      onLayout={props.onLayout}
      {...dataOrientation}
      ref={scrollView}
    >
      {elmts.map(function (elmt, index) {
        return (
          <View
            key={index}
            style={{
              width: "100%",
              height: "100%",
            }}
            data-snap-child={true}
          >
            {elmt}
          </View>
        );
      })}
    </ScrollView>
  );
});

export default ViewPager;

export const PagerViewOnPageScrollEventData = undefined as any;
