import { useAppSelector } from "@constituentvoice/cv-elements/web";
import { useEffect } from "react";

export const useAppTitle = (title: string) => {
  const client = useAppSelector((state) => state.client.client);

  useEffect(() => {
    if (client) {
      document.title = `${title} | ${client.vendor}`;
      return;
    }

    document.title = title;
  }, [client, title]);
};
