import {
  Button,
  ButtonGroup,
  Heading,
  View,
  useAuthenticator,
  useTheme,
} from "@aws-amplify/ui-react";
import {
  LoginClientHeader,
  getSelectedClientLambda,
} from "@constituentvoice/cv-elements/web";
import { faQuestionCircle } from "@fortawesome/pro-regular-svg-icons/faQuestionCircle";
import { faUserLock } from "@fortawesome/pro-regular-svg-icons/faUserLock";
import { faUserPlus } from "@fortawesome/pro-regular-svg-icons/faUserPlus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Client = Exclude<
  Awaited<ReturnType<typeof getSelectedClientLambda>>,
  null
>;

interface ResetPasswordProps {
  client: Client;
  supportOnPress: () => void;
}
export const SignIn = (props: ResetPasswordProps) => ({
  Header() {
    return (
      <View>
        <LoginClientHeader client={props.client} />
        <Heading level={3} marginLeft="2rem">
          Please Sign In
        </Heading>
      </View>
    );
  },
  Footer() {
    const { toSignUp, toResetPassword } = useAuthenticator();
    const { tokens } = useTheme();
    return (
      <ButtonGroup
        direction="column"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        wrap="nowrap"
        gap={tokens.space.small}
        marginTop="-1rem"
        marginBottom={tokens.space.small}
        marginLeft="2rem"
        marginRight="2rem"
        fontWeight="normal"
        size="small"
      >
        <Button onClick={toSignUp} isFullWidth={true}>
          <FontAwesomeIcon icon={faUserPlus} />
          &nbsp; Create an Account
        </Button>
        <Button onClick={toResetPassword} isFullWidth={true}>
          <FontAwesomeIcon icon={faUserLock} />
          &nbsp; Forgot Account or Password?
        </Button>
        <Button onClick={() => props.supportOnPress()} isFullWidth={true}>
          <FontAwesomeIcon icon={faQuestionCircle} />
          &nbsp; Support
        </Button>
      </ButtonGroup>
    );
  },
});
