import { AccountSettings } from "@aws-amplify/ui-react";
import { Toast } from "@constituentvoice/cv-elements/web";
import React from "react";
import { Snackbar } from "react-native-paper";

export const ChangePassword = () => {
  const [showToast, setShowToast] = React.useState(false);

  const handleSuccess = () => {
    setShowToast(true);
  };

  const handleOnDismiss = () => {
    setShowToast(false);
  };

  return (
    <>
      <AccountSettings.ChangePassword onSuccess={handleSuccess} />
      <Toast
        duration={Snackbar.DURATION_SHORT}
        onDismiss={handleOnDismiss}
        visible={showToast}
      >
        Password changed successfully
      </Toast>
    </>
  );
};
