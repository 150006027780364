import {
  DisplayErrorMessagesIfPresent,
  MeetingDetailShareUI,
  getMeetingV5,
  useMeetingDetailShare,
} from "@constituentvoice/cv-elements/web";
import * as React from "react";

import { createACCirclesArray } from "../../utils/helper";

interface MeetingDetailShareViewProps {
  meeting: Awaited<ReturnType<typeof getMeetingV5>> | undefined;
  sheetID: string;
}

export const MeetingDetailShareView = ({
  meeting,
  sheetID,
}: MeetingDetailShareViewProps) => {
  const {
    takePictureError,
    showGalleryError,
    shareError,
    share,
    scrollHandlers,
    shareMessage,
    setShareMessage,
    setKeyboardHeight,
    keyboardHeight,
    assets,
    setAssets,
    showGallery,
    takePicture,
    actionSheetRef,
  } = useMeetingDetailShare({
    meeting,
  });

  return (
    <>
      <DisplayErrorMessagesIfPresent
        errors={[
          { name: "Taking a Picture", val: takePictureError },
          { name: "Showing your Gallery", val: showGalleryError },
          { name: "Sharing this Meeting", val: shareError },
        ]}
      />
      <MeetingDetailShareUI
        share={share}
        actionSheetRef={actionSheetRef}
        scrollHandlers={scrollHandlers}
        shareMessage={shareMessage}
        setShareMessage={setShareMessage}
        setKeyboardHeight={setKeyboardHeight}
        keyboardHeight={keyboardHeight}
        assets={assets}
        setAssets={setAssets}
        showGallery={showGallery}
        takePicture={takePicture}
        sheetID={sheetID}
        createACCirclesArray={createACCirclesArray}
      />
    </>
  );
};
