import { MyNotesUI, useMyNotes } from "@constituentvoice/cv-elements/web";

import { useAppTitle } from "../../hooks/use-client-title";

import { NoNotesIllustration } from "./MyNotes.styles";

export const MyNotesScreenView = () => {
  useAppTitle("Notes");

  const props = useMyNotes();
  return (
    <MyNotesUI
      {...props}
      noNotesIllustration={
        <NoNotesIllustration src={"/images/no-notes-illustration.png"} />
      }
    />
  );
};
