import {
  listClientsLambda,
  useAppSelector,
  useNetworkingParamsList,
} from "@constituentvoice/cv-elements/web";
import { useQuery } from "@tanstack/react-query";

export const useGetGroups = () => {
  const env = useAppSelector((state) => state.env.env);

  const networkingProps = useNetworkingParamsList();

  const {
    data,
    isLoading: isInitialLoading,
    isFetching,
    isPending: isLoading,
    error,
  } = useQuery({
    queryKey: ["groups"],

    queryFn: ({ signal }) => {
      return listClientsLambda({
        ...networkingProps,
        signal,
      });
    },
    enabled: !!env?.VERSION_CODE,
    refetchOnWindowFocus: false,
  });

  return { data, isFetching, isLoading, error, isInitialLoading };
};
