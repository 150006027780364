import { SettingsScreenUI } from "@constituentvoice/cv-elements/web";
import * as React from "react";
import { useNavigate } from "react-router-dom";

import { useBuildClientRoute } from "../../hooks/use-build-client-route";
import { useAppTitle } from "../../hooks/use-client-title";

export const SettingsScreenView = () => {
  const navigate = useNavigate();
  const buildRoute = useBuildClientRoute();
  useAppTitle("Settings");

  const onChangeYourPassword = () => {
    navigate(buildRoute("/change-password"));
  };

  return <SettingsScreenUI onChangeYourPassword={onChangeYourPassword} />;
};
