import { Component, ErrorInfo, PropsWithChildren } from "react";

import { ErrorBoundaryUI } from "./ErrorBoundary.ui";

interface ErrorBoundaryProps extends PropsWithChildren {}

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  { error: null | Error; errorShown: boolean }
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: null, errorShown: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { error: error };
  }
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // TODO: log/email the error to an error reporting service
    // ex:  logErrorToMyService(error, errorInfo);
  }

  toggleErrorShown = () => {
    this.setState({
      ...this.state,
      errorShown: !this.state.errorShown,
    });
  };

  reloadApp = () => {
    location.reload();
  };

  render() {
    if (this.state.error) {
      return (
        <ErrorBoundaryUI
          error={this.state.error}
          errorShown={this.state.errorShown}
          toggleErrorShown={this.toggleErrorShown}
          reloadApp={this.reloadApp}
        />
      );
    }
    return <>{this.props.children}</>;
  }
}
