import styled from "styled-components";

export const AlertDialogContainer = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  box-shadow:
    0px 10px 27px 0px rgba(0, 0, 0, 0.19),
    0px 0.75px 9px 0px rgba(0, 0, 0, 0.04);
  border-radius: 0.75rem;
  min-width: min(80vw, 20rem);
  max-width: 36rem;
`;

export const AlertDialogTextsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.5rem;
  gap: 0.75rem;
  flex: 1;
`;

export const AlertDialogTitle = styled.h3`
  color: ${(p) => p.theme.colors.onSurfaceVariant};
  ${(p) => p.theme.typography.headline[3]};
`;

export const AlertDialogText = styled.p`
  color: ${(p) => p.theme.colors.onSurfaceVariant};
  ${(p) => p.theme.typography.body};
`;

export const AlertDialogDate = styled.span`
  color: ${(p) => p.theme.colors.onPrimary};
  ${(p) => p.theme.typography.label.large};
`;

export const AlertDialogButtonsContainer = styled.div`
  display: flex;
  padding: 1.5rem;
  justify-content: flex-end;
  align-items: center;
  gap: 1.5rem;
`;
