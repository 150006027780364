import { useAppSelector } from "@constituentvoice/cv-elements/web";
import { useCallback } from "react";

export const useBuildClientRoute = () => {
  const client = useAppSelector((state) => state.client.client);

  const fn = useCallback(
    (path: string) => {
      if (!path.startsWith("/")) path = `/${path}`;
      return `/${client?.serviceCode}${path}`;
    },
    [client?.serviceCode],
  );

  return fn;
};
