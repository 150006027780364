import { CVButton } from "@constituentvoice/cv-elements/web";
import styled from "styled-components";

export const LegislatorsScrollView = styled.div``;

export const ActivityIndicatorView = styled.div``;

export const LegislatorsHomeInnerView = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
`;

export const LegislatorHomeHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 0 1rem;

  ${(p) => p.theme.breakpoints.from(p.theme.breakpoints.tabletSmall)} {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(10rem, 16rem);
    padding: 0;
  }
`;

export const LegislatorHomeScreenForm = styled.form`
  display: flex;
  align-items: center;
  background: ${(p) => p.theme.colors.surface};
  border-radius: 0.5rem;
  border: 0.125rem solid ${(p) => p.theme.colors.outlineVariant};
  padding: 0.75rem;
  gap: 0.75rem;
  flex: 1;
`;

export const LegislatorHomeScreenSearchButton = styled(CVButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  width: 2.25rem;
  min-width: 2.25rem;
  height: 2.25rem;
  min-height: 2.25rem;
  padding: 0.5rem;
  & > svg.svg-inline--fa {
    width: 1.125rem;
    height: 1.125rem;
  }
`;

export const LegislatorsHomeSearchBar = styled.input`
  display: block;
  border: none;
  background-color: transparent;
  width: 100%;
  height: 100%;
  min-height: 2.25rem;
  ${(p) => p.theme.typography.headline[5]};

  ${(p) => p.theme.breakpoints.from(p.theme.breakpoints.tabletLarge)} {
    ${(p) => p.theme.typography.headline[4]};
  }
`;

export const LegislatorsListContainers = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);

  ${(p) => p.theme.breakpoints.from(p.theme.breakpoints.tabletSmall)} {
    gap: 3rem;
  }

  ${(p) => p.theme.breakpoints.from(p.theme.breakpoints.desktop)} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;
