import {
  CollapsableContents,
  DisplayErrorMessagesIfPresent,
  NoInfoToDisplay,
  RenderCommitteesView,
  getLegislatorCommitteesV5,
} from "@constituentvoice/cv-elements/web";
import { faUsers } from "@fortawesome/pro-solid-svg-icons/faUsers";
import { ActivityIndicator } from "react-native";

interface LegislatorCommitteesViewProps {
  committeeDetails:
    | Awaited<ReturnType<typeof getLegislatorCommitteesV5>>
    | undefined;
  committeesLoading: boolean;
  committeeDetailsError: unknown;
}
export const LegislatorCommitteesView = ({
  committeeDetails = [],
  committeesLoading,
  committeeDetailsError,
}: LegislatorCommitteesViewProps) => {
  if (committeesLoading) return <ActivityIndicator size="large" />;
  return (
    <CollapsableContents icon={faUsers} title={"Committees"}>
      <DisplayErrorMessagesIfPresent
        errors={[
          { name: "Committee Details Error", val: committeeDetailsError },
        ]}
      />
      {committeeDetails.length > 0 ? (
        <RenderCommitteesView
          committeeDetails={committeeDetails}
          committeesLoading={committeesLoading}
          committeeDetailsError={committeeDetailsError}
        />
      ) : (
        <NoInfoToDisplay />
      )}
    </CollapsableContents>
  );
};
