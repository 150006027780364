import {
  APICoSponsorDetailsV5,
  BillDetailScreenUIV5,
  DisplayErrorMessagesIfPresent,
  getBillSponsorsV5,
  useBillDetailV5,
} from "@constituentvoice/cv-elements/web";
import * as React from "react";
import { ActivityIndicator } from "react-native";
import { useNavigate, useParams } from "react-router-dom";

import { useBuildClientRoute } from "../../hooks/use-build-client-route";
import { useAppTitle } from "../../hooks/use-client-title";

export interface IBillRouteParams {
  billId: number | string;
}

export const BillDetailScreenV5 = () => {
  const { billId } = useParams<keyof IBillRouteParams>() as IBillRouteParams;
  const navigate = useNavigate();
  const {
    sponsorDetails,
    sponsorLoading,
    sponsorError,
    filteredSponsors,
    searchTerm,
    setSearchTerm,
    bill,
    eventBillsError,
    eventBillsLoading,
    billVotes,
    billVotesError,
    billVotesLoading,
  } = useBillDetailV5({ billId });

  useAppTitle(bill?.officialId || "Bill Detail");

  const buildRoute = useBuildClientRoute();

  const onLegislatorPress = (
    legislator: Awaited<ReturnType<typeof getBillSponsorsV5>>[0] | undefined,
  ) => {
    if (legislator) {
      navigate(buildRoute(`/legislators-home/${legislator.id}`));
    } else return;
  };

  const onVotePress = (voteId?: string | number) => {
    if (!voteId) return;
    navigate(buildRoute(`/event-bills/${billId}/votes/${voteId}`));
  };

  if (!bill || eventBillsLoading || billVotesLoading) {
    return <ActivityIndicator testID="loading-indicator" size="large" />;
  }

  return (
    <>
      <DisplayErrorMessagesIfPresent
        errors={[
          { name: "Fetching Bill", val: eventBillsError },
          { name: "Fetching Bill Votes", val: billVotesError },
        ]}
      />
      <BillDetailScreenUIV5
        bill={bill}
        sponsorError={sponsorError}
        sponsorLoading={sponsorLoading}
        sponsorDetails={sponsorDetails}
        filteredSponsors={filteredSponsors}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        onLegislatorPress={onLegislatorPress}
        billVotes={billVotes}
        onVotePress={onVotePress}
      />
    </>
  );
};
