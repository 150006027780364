import { CVButton } from "@constituentvoice/cv-elements/web";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

export const StateFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

export const StateFilterView = styled.div<{
  hasSelection?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.75rem;
  border-radius: 0.75rem;
  padding: 0.375rem 0.75rem;
  border: 2px solid
    ${(p) =>
      p.hasSelection ? p.theme.colors.accent : p.theme.colors.outlineVariant};
  background-color: ${(p) =>
    p.hasSelection ? p.theme.colors.accentLight : p.theme.colors.surface};
  color: ${(p) =>
    p.hasSelection ? p.theme.colors.accent : p.theme.colors.onPrimary};
  transition: all 0.1s ease-in-out;
  position: relative;
  z-index: 100000;

  &:has(> button[aria-haspopup][aria-expanded="true"]) {
    background-color: #fff;
    border-color: #fff;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow:
      0px 2.75px 9px 0px rgba(0, 0, 0, 0.19),
      0px 0.25px 3px 0px rgba(0, 0, 0, 0.04);
  }
`;

export const StateFilterButton = styled(CVButton)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.75rem;
  padding: 0;
  background: none;
  border: none;
  color: inherit;
  width: 100%;
`;

export const IconContainer = styled(CVButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 2.25rem;
  min-height: 2.25rem;
  padding: 0.5rem;
  background: none;

  ${(p) => p.theme.breakpoints.from(p.theme.breakpoints.tabletLarge)} {
    min-width: 3rem;
    min-height: 3rem;
    padding: 0.75rem;
  }
`;

export const OpenIcon = styled(FontAwesomeIcon)<{ isOpen?: boolean }>`
  transition: transform 0.25s ease-in-out;
  transform: rotate(${(p) => (p.isOpen ? 180 : 0)}deg);
`;

export const StateFilterValue = styled.span`
  text-align: start;
  min-width: 7rem;
  ${(p) => p.theme.typography.label.medium};

  ${(p) => p.theme.breakpoints.from(p.theme.breakpoints.tabletLarge)} {
    ${(p) => p.theme.typography.label.large};
  }
`;
