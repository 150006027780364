import {
  VotesDetailScreenUI,
  useVoteDetailV5,
} from "@constituentvoice/cv-elements/web";
import { useParams } from "react-router-dom";

import { VotedLegislatorsListsView } from "./components/VotedLegislatorsLists.view";

interface VoteDetailScreenViewProps {
  billId: number | string;
  voteId: number | string;
}

export const VoteDetailScreenViewV5 = () => {
  const { billId, voteId } = useParams<
    keyof VoteDetailScreenViewProps
  >() as VoteDetailScreenViewProps;

  const {
    vote,
    votesError,
    votesLoading,
    votedNoLegislators,
    votedYesLegislators,
  } = useVoteDetailV5({
    billId,
    voteId,
  });

  if (votesLoading) return <p>Loading...</p>;
  return (
    <VotesDetailScreenUI
      vote={vote}
      votedLegislatorsListsElement={
        // TODO: Use the list of legislators from the `vote` object
        <VotedLegislatorsListsView
          votedYesLegislators={votedYesLegislators}
          votedNoLegislators={votedNoLegislators}
        />
      }
    />
  );
};
