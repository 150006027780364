import { useState } from "react";
import { useRouteError } from "react-router-dom";

import { ErrorBoundaryUI } from "./ErrorBoundary.ui";

export function ReactRouterErrorBoundary() {
  const error = useRouteError() as Error;
  const [errorShown, setErrorShown] = useState(false);
  const toggleErrorShown = () => setErrorShown((v) => !v);
  const reloadApp = () => {
    location.reload();
  };

  return (
    <div style={{ height: "100vh", width: "100vw" }}>
      <ErrorBoundaryUI
        error={error}
        errorShown={errorShown}
        toggleErrorShown={toggleErrorShown}
        reloadApp={reloadApp}
      />
    </div>
  );
}
