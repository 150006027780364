import { openWebPage } from "@constituentvoice/cv-elements/web";

import {
  CarouselContainer,
  CarouselDots,
  CarouselProvider,
  Slide,
  SlideImage,
  Slider,
} from "./SponsorsAds.web.styles";
import "pure-react-carousel/dist/react-carousel.es.css";

interface AdItem {
  id: number;
  adOrder?: string | number | null;
  adLink?: string;
  adImageUrl: string;
  serviceId: number;
  serviceProductId: number;
}

interface SponsorsAdsProps {
  ads: Array<AdItem>;
}

const VISIBLE_AD_SLIDES = 3;

export const SponsorsAds = (props: SponsorsAdsProps) => {
  const { ads } = props;
  const canSlideAutomatically = ads.length > VISIBLE_AD_SLIDES;
  if (!ads.length) return null;
  return (
    <CarouselContainer>
      <CarouselProvider
        visibleSlides={
          ads.length > VISIBLE_AD_SLIDES ? VISIBLE_AD_SLIDES : ads.length
        }
        totalSlides={ads.length}
        naturalSlideWidth={148}
        naturalSlideHeight={148}
        interval={canSlideAutomatically ? 5000 : undefined}
        isPlaying={canSlideAutomatically}
        touchEnabled={canSlideAutomatically}
        dragEnabled={canSlideAutomatically}
        infinite
      >
        <Slider>
          {ads.map((ad, index) => {
            return (
              <Slide index={Number(ad.adOrder || index)} key={`ad-${index}`}>
                <SlideImage
                  data-testid={`ad_item_${ad.id}`}
                  src={ad.adImageUrl}
                  role={"button"}
                  onClick={() => {
                    if (ad.adLink) openWebPage(ad.adLink);
                  }}
                />
              </Slide>
            );
          })}
        </Slider>
        {canSlideAutomatically && (
          <CarouselDots
            disableActiveDots={false}
            showAsSelectedForCurrentSlideOnly
          />
        )}
      </CarouselProvider>
    </CarouselContainer>
  );
};
