import styled from "styled-components";

export const NoBillsIllustration = styled.img`
  display: block;
  object-fit: contain;
  object-position: center;
  max-width: 16rem;

  ${(p) => p.theme.breakpoints.from(p.theme.breakpoints.tabletSmall)} {
    max-width: 26rem;
  }
`;
