import styled from "styled-components";

export const HiddenAvatarInput = styled.input.attrs({
  hidden: true,
  "aria-hidden": true,
})`
  display: none;
  visibility: hidden;
  pointer-events: none;
  user-select: none;
`;
