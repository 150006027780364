import {
  USState,
  useAllLegislatorsV5,
  useEventLegislatorsScreenV5,
} from "@constituentvoice/cv-elements/web";
import { faSearch } from "@fortawesome/pro-regular-svg-icons/faSearch";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { FormEventHandler, useState } from "react";

import { AllLegislatorsScreenView } from "../AllLegislators/AllLegislators.view";
import { EventLegislatorsScreenView } from "../EventLegislators";

import { StateFilter } from "./components/StateFilter";
import {
  LegislatorHomeHeader,
  LegislatorHomeScreenForm,
  LegislatorHomeScreenSearchButton,
  LegislatorsHomeInnerView,
  LegislatorsHomeSearchBar,
  LegislatorsListContainers,
} from "./LegislatorsHomeScreen.styles";

export const LegislatorsHomeScreenViewV5 = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const {
    allLegislators,
    allLegislatorsLoading,
    allLegislatorsRefetching,
    refetchAllLegislators,
    allLegislatorsError,
  } = useAllLegislatorsV5();

  const { eventLegislators, eventLegislatorsLoading, eventLegislatorsError } =
    useEventLegislatorsScreenV5();
  const [searchInput, setSearchInput] = React.useState("");
  const [stateFilter, setStateFilter] = React.useState<
    Set<USState["abbreviation"]>
  >(new Set([]));

  /**
   *
   * All Legislators
   * - filtered by searchTerm in query (packages/cv-elements/src/screens/AllLegislators/AllLegislators.logic.ts)
   *
   * Event Legislators (Legislators in Meetings)
   * - feature added! eventLegislators are now filtered (websites/web-portal/src/screens/Legislators/EventLegislators/EventLegislatorsScreen.view.tsx)
   */
  const handleSearchIconButton: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    setSearchTerm(searchInput);
  };

  const handleClearSearchButton = () => {
    setSearchTerm("");
    setSearchInput("");
  };

  return (
    <LegislatorsHomeInnerView>
      <LegislatorHomeHeader>
        <LegislatorHomeScreenForm onSubmit={handleSearchIconButton}>
          <LegislatorHomeScreenSearchButton
            aria-label={"Search"}
            type={"submit"}
          >
            <FontAwesomeIcon icon={faSearch} />
          </LegislatorHomeScreenSearchButton>
          <LegislatorsHomeSearchBar
            id="legislator-search"
            placeholder="John Doe"
            type="text"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
          <LegislatorHomeScreenSearchButton
            type="button"
            onClick={handleClearSearchButton}
          >
            <FontAwesomeIcon icon={faTimes} />
          </LegislatorHomeScreenSearchButton>
        </LegislatorHomeScreenForm>
        <StateFilter
          selectedKeys={stateFilter}
          onSelectionChange={setStateFilter}
        />
      </LegislatorHomeHeader>
      <LegislatorsListContainers>
        <EventLegislatorsScreenView
          searchTerm={searchTerm}
          stateFilter={stateFilter}
          eventLegislators={eventLegislators}
          eventLegislatorsLoading={eventLegislatorsLoading}
          eventLegislatorsError={eventLegislatorsError}
        />
        <AllLegislatorsScreenView
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          stateFilter={stateFilter}
          allLegislators={allLegislators || []}
          allLegislatorsLoading={allLegislatorsLoading}
          allLegislatorsRefetching={allLegislatorsRefetching}
          refetchAllLegislators={refetchAllLegislators as never}
          allLegislatorsError={allLegislatorsError}
        />
      </LegislatorsListContainers>
    </LegislatorsHomeInnerView>
  );
};
