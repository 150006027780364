import {
  DotGroup,
  CarouselProvider as PureCarouselProvider,
  Slide as PureSlide,
  Slider as PureSlider,
} from "pure-react-carousel";
import styled from "styled-components";

export const CarouselContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 32.25rem;
  padding: 3rem 0;
`;

export const CarouselProvider = styled(PureCarouselProvider)`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 100%;
  gap: 1.5rem;
`;

export const Slider = styled(PureSlider)`
  flex-direction: row;
  align-items: center;
  max-width: 100%;
  user-select: none;
  --carousel-item-height: 6rem;
  ${(p) => p.theme.breakpoints.from(p.theme.breakpoints.tabletSmall)} {
    --carousel-item-height: 7.5rem;
  }
  ${(p) => p.theme.breakpoints.from(p.theme.breakpoints.tabletLarge)} {
    --carousel-item-height: 9.25rem;
  }
  height: var(--carousel-item-height, 6rem);
`;

export const Slide = styled(PureSlide)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease-in-out;
  user-select: none;
  max-height: var(--carousel-item-height, 6rem);

  &.carousel__slide--focused {
    outline: none;
    & img {
      border: 2px solid ${(p) => p.theme.colors.accent};
    }
  }

  & .carousel__slide-focus-ring {
    display: none;
    visibility: hidden;
  }
`;

export const SlideImage = styled.img`
  margin: auto;
  width: var(--carousel-item-height, 6rem);
  height: var(--carousel-item-height, 6rem);
  object-fit: contain;
  object-position: center;
  border-radius: 0.75rem;
  padding: 0.25rem;
  cursor: pointer;
  border: 2px solid rgba(0, 0, 0, 0);
`;

export const CarouselDots = styled(DotGroup)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  & > button.carousel__dot {
    background-color: ${(p) => p.theme.colors.surfaceAlpha[30]};
    height: 0.75rem;
    width: 0.75rem;
    border-radius: 50%;
    border: none;
  }

  & > button.carousel__dot--selected {
    background-color: ${(p) => p.theme.colors.surfaceAlpha[60]};
  }
`;
