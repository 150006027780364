import { EnvironmentalVars } from "@constituentvoice/cv-elements/web";

export const ENVIRONMENTS = {
  DEVELOPMENT: "development",
  STAGING: "staging",
  PRODUCTION: "production",
  LOCAL: "local",
  TRAINING: "training",
  SANDBOX: "sandbox",
};

export const ENVIRONMENT =
  import.meta.env?.VITE_BUILD_ENVIRONMENT ?? ENVIRONMENTS.LOCAL;

export const PARENT_CODE = "advocacyassoc";
export const CLIENT_CODE = "advocacyday";

export const config: EnvironmentalVars = {
  ENVIRONMENT,
  CLIENT_LIST_URL: {
    DEVELOPMENT: `https://lambda.advocacyday.dev/list-clients`,
    STAGING: `https://lambda.advocacyday.live/list-clients`,
    PRODUCTION: `https://lambda.advocacyday.net/list-clients`,
    TRAINING: `https://lambda.advocacy.day/list-clients`,
    LOCAL: `http://localhost:5129/v5/clients/`,
    SANDBOX: `https://lambda.advocacyaction.us/list-clients`,
  },
  GQL_URL: {
    DEVELOPMENT: `advocacyday.dev/graphql`,
    STAGING: `advocacyday.live/graphql`,
    PRODUCTION: `advocacyday.net/graphql`,
    TRAINING: `advocacy.day/graphql`,
    LOCAL: `localhost:5129/graphql`,
    SANDBOX: `advocacyaction.us/graphql`,
  },
  API_URL: {
    DEV_V5: `advocacyday.dev/v5`,
    STAGING_V5: `advocacyday.live/v5`,
    TRAINING: `advocacy.day/v5`,
    PROD_V5: `advocacyday.net/v5`,
    LOCAL_V5: "localhost:5129/v5",
    SANDBOX: "advocacyaction.us/v5",
  },
  // TODO: Migrate to check for iOS or Android and change the string
  GOOGLE_MAPS: {
    iosApiKey: import.meta.env?.VITE_GOOGLE_MAPS_IOS_API_KEY ?? "",
    androidApiKey: import.meta.env?.VITE_GOOGLE_MAPS_ANDROID_API_KEY ?? "",
  },
  VERSION_CODE: "1.0.0",
};
