export const cvAppSettings = {
  useSocket: false,
  bottomNavigators: {
    meetings: true,
    legislators: true,
    messages: true,
    directory: true,
    actionCenter: false,
    resources: false,
    more: true,
  },
  meetings: {
    options: {
      share: true,
      meetingAttendance: true,
      surveyTitle: undefined,
      scheduleDisabledWarning: undefined,
    },
    screens: {
      detail: true,
    },
  },
  legislators: {
    bioDocument: false,
  },
  more: {
    options: {
      bills: true,
      eventFeedback: true,
      profile: true,
      documents: true,
      otherResources: true,
      support: true,
      twitter: false,
      otherLobbyDays: true,
      messages: false,
      about: true,
      myNotes: false,
    },
  },
  login: {
    useBackground: false,
  },
  parent: true,
};
