import { View } from "react-native";
import { forwardRef } from "react";
import type { MapViewProps, MapMarkerProps } from "react-native-maps/lib/index";

const MockMapView = forwardRef(
  ({ testID, children, ...props }: MapViewProps, ref: any) => {
    if (ref?.current) {
      ref.current = {
        getMapBoundaries: async () => ({
          northEast: {
            latitude: 2,
            longitude: 2,
          },
          southWest: {
            latitude: 1,
            longitude: 1,
          },
        }),
        getCamera: async () => ({
          center: {
            latitude: 2,
            longitude: 2,
          },
          heading: 1,
          pitch: 1,
          zoom: 1,
          altitude: 1,
        }),
        animateCamera: () => {},
      };
    }

    return (
      <View testID={testID} {...props}>
        {children}
      </View>
    );
  }
);

const MockMarker = forwardRef(
  ({ testID, children, ...props }: MapMarkerProps, ref: any) => {
    if (ref?.current) {
      ref.current = {
        redraw: () => {},
      };
    }

    return (
      <View testID={testID} {...props}>
        {children}
      </View>
    );
  }
);

const mockMapTypes = {
  STANDARD: 0,
  SATELLITE: 1,
  HYBRID: 2,
  TERRAIN: 3,
  NONE: 4,
  MUTEDSTANDARD: 5,
};

// module.exports = Object.assign(MockMapView, {
//   Marker: MockMarker,
//   MAP_TYPES: mockMapTypes,
//   PROVIDER_DEFAULT: "default",
//   PROVIDER_GOOGLE: "google",
// });

export default MockMapView;

export const Marker = MockMarker;
export const MAP_TYPES = mockMapTypes;
export const PROVIDER_DEFAULT = "default";
export const PROVIDER_GOOGLE = "google";
