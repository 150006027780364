import { useMenuItem } from "@react-aria/menu";
import { TreeState } from "@react-stately/tree";
import { useRef } from "react";

type Node<T> = TreeState<T>["collection"] extends Iterable<infer Q> ? Q : never;

interface MenuItemProps<T> {
  item: Node<T>;
  state: TreeState<T>;
}

export const MenuItem = <T extends object>(props: MenuItemProps<T>) => {
  const ref = useRef(null);
  const { item, state } = props;
  const { menuItemProps } = useMenuItem({ key: item.key }, state, ref);
  return (
    <li {...menuItemProps} ref={ref}>
      {item.rendered}
    </li>
  );
};
