import {
  CollapsableContents,
  DisplayErrorMessagesIfPresent,
  TalkingPointsScreenUI,
  useTalkingPointsV5,
} from "@constituentvoice/cv-elements/web";
import { faPresentation } from "@fortawesome/pro-regular-svg-icons/faPresentation";
import * as React from "react";
import { ActivityIndicator } from "react-native";
import { useParams } from "react-router-dom";

interface IRouteTalkingPoints {
  meetingId: number | string;
}

export const TalkingPointsScreenViewV5 = () => {
  const { meetingId } = useParams<
    keyof IRouteTalkingPoints
  >() as IRouteTalkingPoints;

  const { dataTalkingPoints, loading, talkingPointError } = useTalkingPointsV5({
    meetingId,
  });

  if (loading)
    return <ActivityIndicator accessibilityLabel="Loading..." size="large" />;

  return (
    <CollapsableContents icon={faPresentation} title={"Talking points"}>
      <DisplayErrorMessagesIfPresent
        errors={[{ name: "Fetching Talking Points", val: talkingPointError }]}
      />
      <TalkingPointsScreenUI
        webTalkingPoints={(dataTalkingPoints || []).map(
          (it: { htmlTalkingPoint: string }) => it.htmlTalkingPoint,
        )}
        noTalkingPoints={!dataTalkingPoints?.length}
      />
    </CollapsableContents>
  );
};
