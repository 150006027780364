import styled from "styled-components";

export const RobotImage = styled.img`
  flex-shrink: 1;
  aspect-ratio: 1.6;
  resize-mode: contain;
  max-height: 300px;
  align-self: center;
  object-fit: contain;
  border-bottom: white;
`;
