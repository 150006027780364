import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons/faInfoCircle";
import { faRedo } from "@fortawesome/pro-solid-svg-icons/faRedo";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import * as React from "react";
import { ScrollView, View } from "react-native";

import {
  ErrorMessageText,
  ErrorSafeAreaView,
  ErrorText,
  ErrorView,
  ReloadPressable,
  ReloadView,
  SectionedErrorView,
  ShowMorePressable,
  ShowMoreText,
  SomethingWrongView,
} from "./ErrorBoundary.styles";
import { RobotImage } from "./ErrorBoundaryWeb.styles";

export interface ErrorBoundaryUIProps {
  error: Error;
  errorShown: boolean;
  toggleErrorShown: () => void;
  reloadApp: () => void;
}

export const ErrorBoundaryUI = ({
  error,
  errorShown,
  toggleErrorShown,
  reloadApp,
}: ErrorBoundaryUIProps) => {
  return (
    <ErrorSafeAreaView>
      <ScrollView>
        <ErrorView>
          <SomethingWrongView>
            <ErrorText>{"Oh no!"}</ErrorText>
            <ErrorText>{"Something went wrong and the app crashed."}</ErrorText>
            <RobotImage src="/robot.jpg" />
          </SomethingWrongView>
          <ReloadView>
            <ReloadPressable onPress={() => reloadApp()}>
              <FontAwesomeIcon icon={faRedo} color="black" size={16} />
              <ShowMoreText>Tap here to reload the app.</ShowMoreText>
            </ReloadPressable>
          </ReloadView>
          <SectionedErrorView>
            <ShowMorePressable onPress={() => toggleErrorShown()}>
              <FontAwesomeIcon icon={faInfoCircle} color="black" size={20} />
              <ShowMoreText>Tap here to show more.</ShowMoreText>
            </ShowMorePressable>
          </SectionedErrorView>
          <View>
            {errorShown ? (
              <ErrorMessageText>{error?.message ?? error}</ErrorMessageText>
            ) : null}
          </View>
        </ErrorView>
      </ScrollView>
    </ErrorSafeAreaView>
  );
};
